import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import moment from "moment";
import getResponse from "../../utilities/FetchHelper";
import configuration from "../../config";

import Modal from "../Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ScheduleEventName from "./ScheduleEventName";
import StartEndTime from "./StartEndTime";
import LowerUpperMode from "./LowerUpperMode";
import ZoneSelector from "./ZoneSelector";

import icon_event from "../../assets/icon_event.svg";
import AuthenticatedComponent from "../../utilities/AuthenticatedComponent";

const EditEventModal = ({
    modalTitle,
    pageName,
    clearModal,
    currentCampusId,
    eventList,
    selectedEventData,
    setProcessing,
    errorMessage,
    setErrorMessage,
    errorMessageRef,
    getEvents
}) => {
    const authenticatedComponent = new AuthenticatedComponent
    const newDate = new Date()
    let year = newDate.toLocaleString("default", { year: "numeric" });
    let month = newDate.toLocaleString("default", { month: "2-digit" });
    let day = newDate.toLocaleString("default", { day: "2-digit" });
    let formattedDate = year + "-" + month + "-" + day;

    const [eventName, setEventName] = useState(selectedEventData && selectedEventData.overrideName)
    const [selectedFormattedDate, setSelectedFormattedDate] = useState()

    const [selectedDate, setSelectedDate] = useState(null)
    const [selectedFromHour, setSelectedFromHour] = useState(null)
    const [selectedFromMin, setSelectedFromMin] = useState(null)
    const [selectedToHour, setSelectedToHour] = useState(null)
    const [selectedToMin, setSelectedToMin] = useState(null)
    const [selectedStartTime, setSelectedStartTime] = useState(null)
    const [selectedEndTime, setSelectedEndTime] = useState(null)
    const [selectedThermalZones, setSelectedThermalZones] = useState([])
    const [selectedBlockCooling, setSelectedBlockCooling] = useState(null)
    const [selectedBlockHeating, setSelectedBlockHeating] = useState(null)
    const [selectedLowerBound, setSelectedLowerBound] = useState(null)
    const [selectedUpperBound, setSelectedUpperBound] = useState(null)
    const [zoneList, setZoneList] = useState([])
    const [eventForm, setEventForm] = useState(null)

    const hasZoneSelected = selectedThermalZones.length === 0

    useEffect(() => {
        authenticatedComponent.getEventAssignmentList(currentCampusId, eventsAssignmentCallback)
    }, [])

    const eventsAssignmentCallback = (data) => {
        Object.entries(data).forEach(([key, value]) => {
            setZoneList(value)
        })
    }

    useEffect(() => {
        if (selectedEventData) {
            setEventName(selectedEventData.overrideName)
            setSelectedFromHour(selectedEventData.fromTime.substr(11, 2))
            setSelectedFromMin(selectedEventData.fromTime.substr(14, 2))
            setSelectedToHour(selectedEventData.toTime.substr(11, 2))
            setSelectedToMin(selectedEventData.toTime.substr(14, 2))
            setSelectedStartTime(selectedEventData.fromTime.substr(11, 5))
            setSelectedEndTime(selectedEventData.toTime.substr(11, 5))
            setSelectedThermalZones(selectedEventData.thermalZones)
            setSelectedBlockCooling(selectedEventData.instruction.blockCooling)
            setSelectedBlockHeating(selectedEventData.instruction.blockHeating)
            setSelectedLowerBound(selectedEventData.instruction.lowerBound)
            setSelectedUpperBound(selectedEventData.instruction.upperBound)
            setSelectedDate(selectedEventData.fromTime.substr(0, 10))
            
            let adjustedDate = selectedEventData.fromTime.substr(0, 10).replaceAll('-', ',')
            adjustedDate = new Date(adjustedDate)
            setSelectedFormattedDate(adjustedDate)

            setEventForm({
                campusEntityKey: currentCampusId,
                eventId: selectedEventData.overrideId,
                eventName: selectedEventData.overrideName,
                blockCooling: selectedEventData.instruction.blockCooling,
                blockHeating: selectedEventData.instruction.blockHeating,
                lowerBound: selectedEventData.instruction.lowerBound,
                upperBound: selectedEventData.instruction.upperBound,
                startTime: selectedEventData.fromTime.substr(11, 5),
                endTime: selectedEventData.toTime.substr(11, 5),
                date: selectedEventData.fromTime.substr(0, 10),
            })
        }
    }, [selectedEventData])

    const handleName = (name) => {
        setEventName(name)

        setEventForm(prevState => ({...prevState, 
            eventName: name,
        }))
    }

    const handleChange = (index = null, name, value) => {
        let newZoneList

        // lowerbound upperbound mode
        if (name === 'lowerBound') {
            setSelectedLowerBound(value)

            setEventForm(prevState => ({...prevState, 
                lowerBound: value,
            }))
        } else if (name === 'upperBound') {
            setSelectedUpperBound(value)

            setEventForm(prevState => ({...prevState, 
                upperBound: value,
            }))
        } else if (name === 'heat') {
            setSelectedBlockCooling(true)
            setSelectedBlockHeating(false)

            setEventForm(prevState => ({...prevState, 
                blockCooling: true,
                blockHeating: false,
            }))
        } else if (name === 'cool') {
            setSelectedBlockCooling(false)
            setSelectedBlockHeating(true)

            setEventForm(prevState => ({...prevState, 
                blockCooling: false,
                blockHeating: true,
            }))
        } else if (name === 'fan') {
            setSelectedBlockCooling(true)
            setSelectedBlockHeating(true)

            setEventForm(prevState => ({...prevState, 
                blockCooling: true,
                blockHeating: true,
            }))
        } else if (name === 'auto') { 
            setSelectedBlockCooling(false)
            setSelectedBlockHeating(false)

            setEventForm(prevState => ({...prevState, 
                blockCooling: false,
                blockHeating: false,
            }))
        }

        //start end time
        //format: "2023-12-09T12:27:00"
        if (name === 'startTime') {
            setSelectedStartTime(value)

            setEventForm(prevState => ({...prevState, 
                startTime: value,
            }))
        } else if (name === 'endTime') {
            setSelectedEndTime(value)

            setEventForm(prevState => ({...prevState, 
                endTime: value,
            }))
        }

        //date
        if (name === 'date') {
            let newDay = value.getDate() < 10 ? `0${value.getDate()}` : value.getDate()
            let indexMonth = value.getMonth() + 1
            let newMonth = indexMonth < 10 ? `0${indexMonth}` : indexMonth

            setSelectedDate(value.getFullYear()
            + '-' +
            newMonth
            + '-' +
            newDay)

            setSelectedFormattedDate(value)

            setEventForm(prevState => ({...prevState, 
                date: value.getFullYear()
                + '-' +
                newMonth
                + '-' +
                newDay
            }))
        }

        //selected zones
        if (name === 'selectedZones') {
            newZoneList = value.map((zone) => `thermalZoneEntityKey=${zone}`).join('&')
            setSelectedThermalZones(newZoneList)
        }
    }

    const saveUpdatedEvent = () => {
        setProcessing(true)

        const encodeGetParams = p => 
        Object.entries(p).map(item => item.map(encodeURIComponent).join("=")).join("&")

        let newParams = encodeGetParams(eventForm)
        let newEventForm = newParams + '&' + selectedThermalZones

        getResponse(configuration["backend_host"] + "/ahiapi/"
        + currentCampusId + "/event",
        false,
        "POST", 
        newEventForm,
        getEvents,
        setProcessing,
        errorMessage,
        setErrorMessage,
        errorMessageRef
        )
    }

    return (
        <Modal
            modalTitle={modalTitle}
            modalImage={icon_event}
            clearModal={clearModal}
            shouldDisable={hasZoneSelected}
            updateSettings={saveUpdatedEvent}
            renderModalContent={(<>
                {eventForm && eventForm !== null && (<>
                    <EventNameDate className="event-name-date-container">
                        <ScheduleEventName 
                            className="name-container"
                            pageName={pageName}
                            handleName={handleName}
                            scheduleName={eventName}
                        />

                        <div className="date-container">
                            <label>
                                Date
                                <div className="date-input-container">
                                    <DatePicker 
                                        selected={selectedFormattedDate}
                                        onChange={(date) => handleChange(null, 'date', date)}
                                    />
                                </div>
                            </label>
                        </div>
                    </EventNameDate>

                    <EventSettings className="event-settings-container">
                        <StartEndTime
                            selectedFromHour={selectedFromHour}
                            selectedFromMin={selectedFromMin}
                            selectedToHour={selectedToHour}
                            selectedToMin={selectedToMin}
                            handleChange={handleChange}
                        />
                        <EditEvent className="edit-event-container">
                            <div className="edit-event-settings">
                                <ZoneSelector
                                    selectedZones={selectedThermalZones}
                                    zoneList={zoneList}
                                    eventList={eventList}
                                    handleChange={handleChange}
                                />
                        
                                <div className="end-container">
                                    <LowerUpperMode 
                                        selectedLower={selectedLowerBound}
                                        selectedUpper={selectedUpperBound}
                                        selectedBlockCooling={selectedBlockCooling}
                                        selectedBlockHeating={selectedBlockHeating}
                                        handleChange={handleChange}
                                    />
                                </div>
                            </div>
                        </EditEvent>
                    </EventSettings>
                </>)}
            </>)}
        /> 
    )
}

const EditEvent = styled.div`
    .edit-event-settings {
        display: flex;
        justify-content: space-between;
        padding: 24px 0;
        width: 100%;

        & > div:nth-of-type(1) {
            width: 48%
        }

        & > div:nth-of-type(2) {
            width: 30%
        }

        .end-container {
            align-items: flex-end;
            display: flex;
            flex-flow: column;
            justify-content: space-between;

            .remove {
                cursor: pointer;
                font-size: 12px;
                text-decoration: underline;
            }
        }
    }
`

const EventNameDate = styled.div`
    align-items: center;  
    display: flex;
    justify-content: space-between;

    .date-container,
    .schedule-name-container {
        padding-bottom: 18px;
        width: 48% !important;
    }

    .date-container {
        label {
            font-weight: 500;
        }
        .date-input-container {
            background: #F2F4F7;
            border-radius: 8px;
            margin-top: 10px;
            input {
                border: none;
                cursor: pointer;
                padding: 12px 10px 12px 44px;
            }
        }
    }
`

const EventSettings = styled.div`
    max-height: 428px;

    .time-container {
        display: flex;
        flex-flow: row;
    }

    .time-container > div {
        width: 48%
    }
`

export default EditEventModal