import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import configuration from '../../config'
import getResponse from '../../utilities/FetchHelper'

import Modal from '../Modal'

const EVOverride = ({
    clearModal,
    modalImage,
    selectedGXId,
    setProcessing,
    errorMessage,
    setErrorMessage,
    errorMessageRef,
    updateDeviceData,
    showMobile,
    selectedGroup
}) => {
    const [overrideForm, setOverrideForm] = useState({})
    let durationOptions = []
    const hours = new Array(24).fill(0).map((_, i) => `${i+1}`)

    for (let i = 0; i < hours.length; i++) {
        const hourOption = {
        label: '',
        value: null,
        }

        hourOption.label = hours[i] + `${hours[i] > 1 ? ' hours' : ' hour'}`

        hourOption.value = hours[i] * 60

        durationOptions.push(hourOption)
    }

    useEffect(() => {
        if(selectedGXId) {
            setOverrideForm(prevState => ({
                ...prevState,
                evChargerGroupId: selectedGroup.evChargerGroupId,
                expiresInMinutes: 60
                })
            )
        }
    }, [selectedGXId])

    const setOverride = () => {
        setProcessing(true)
        clearModal()

        getResponse(configuration["backend_host"] + "/ahiapi/"
        + selectedGXId + "/gridInterconnect/evChargerGroup/override",
        true,
        "POST", 
        JSON.stringify(overrideForm),
        updateDeviceData,
        setProcessing,
        errorMessage,
        setErrorMessage,
        errorMessageRef
        )
    }

    return (
        <EVOverrideDiv>
            <Modal
                modalTitle={"Group Override"}
                modalImage={modalImage}
                clearModal={clearModal}
                showMobile={showMobile}
                updateSettings={setOverride}
                renderModalContent={(
                    <div className="override-container">
                        <h5>Override Settings</h5>

                        <div className="override-settings">
                            <div className="power">
                                <label htmlFor="performance">Power Limit</label>
                                <div>
                                    <input 
                                        name="power-limit"
                                        type="number" 
                                        placeholder="0"
                                        onChange={(e) => setOverrideForm(prevState => ({
                                            ...prevState,
                                            overridePercent: parseInt(e.target.value),
                                            })
                                        )} 
                                        min="1" 
                                        max="100"
                                    ></input>
                                    <span>%</span>
                                </div>
                            </div>

                            <div className="duration">
                            <label>
                                Duration
                            </label>
                            <div className="duration-input">
                                <select
                                name="duration"
                                onChange={(e) => setOverrideForm(prevState => ({
                                    ...prevState,
                                    expiresInMinutes: parseInt(e.target.value),
                                    })
                                )}
                                >
                                {durationOptions.map((option) => (
                                    <option
                                    value={option.value}
                                    key={option.value}
                                    >
                                    {option.label}
                                    </option>
                                ))}
                                </select>
                            </div>
                            </div>
                        </div>
                    </div>
                )}
            >
            </Modal>
        </EVOverrideDiv>
  )
}

const EVOverrideDiv = styled.div`
    align-items: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-top: 20px;

    .modal.desktop {
        max-width: 600px;
    }

    h5 {
        font-weight: 500;
        font-style: italic;
        margin: 0 0 12px;
    }
    
    .override-settings {
        align-items: flex-start;
        display: flex;
        justify-content: space-between;

        input {
            background: #EDF1F2;
            border: none;
            border-radius: 8px;
            padding: 6px;
            width: 30px;
        }

        label {
            font-size: 14px;
            font-weight: 600;
        }
    }

    .power {
        justify-content: space-between;
        width: 168px;
    }

    .power,
    .duration {
        align-items: center;
        display: flex;
        height: 28px;
        justify-content: space-between;
        margin: 0 0 12px;
        width: 230px;
    }

    @media screen and (max-width: 768px) {
        .override-settings {
            flex-flow: column;

            .power, 
            .duration {
                width: 100%;
            }
        }
    }
`

export default EVOverride