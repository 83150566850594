import React, { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import exportingModule from 'highcharts/modules/exporting'
import { elexity } from '../utilities/LobbyKioskGraphHelper'
import configuration from '../config'
import AuthenticatedComponent from '../utilities/AuthenticatedComponent'

import KioskModel from '../components/KioskModel'
import CurrentWeather from '../components/CurrentWeather'
import elexity_logo from '../assets/elexity_logo_full.svg'
import globe from '../assets/lobbyKiosk/globe.svg'
import bulb from '../assets/lobbyKiosk/bulb.svg'
import carMiles from '../assets/lobbyKiosk/carMiles.svg'
import trees from '../assets/lobbyKiosk/trees.svg'
import coalBurned from '../assets/lobbyKiosk/coalBurned.svg'
import energyPurchased from '../assets/lobbyKiosk/energyPurchased.svg'
import solar from '../assets/kiosk/solar.svg'
import meter from '../assets/kiosk/gxMeter.svg'
import { setCurrentGX, setCurrentGXData } from '../actions'
import { connect } from 'react-redux'
import WithRouter from '../utilities/WithRouter'

require('highcharts/modules/accessibility')(Highcharts)
require('highcharts/modules/draggable-points')(Highcharts)
require("highcharts/modules/exporting")(Highcharts)
require("highcharts/modules/export-data")(Highcharts)

const LobbyKiosk = ({ 
    currentCampusData,
    currentGXData,
    currentGXId,
    setCurrentGX,
    setCurrentGXData,
    updateGXData,
}) => {
    exportingModule(Highcharts)

    const [gridData, setGridData] = useState(currentGXData?.kiosk !== undefined ? currentGXData : null)
    
    const liveChartRef = useRef(null)
    const authenticatedComponent = new AuthenticatedComponent
    const hasGridData = gridData !== null && gridData?.kiosk

    //Format stats
    const formatValue = (value) => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    
    let formattedEnergyConsumed = formatValue(hasGridData && gridData.kiosk.thirtyDayEnergyConsumedkWh)
    let formattedEnergyGenerated = formatValue(hasGridData && gridData.kiosk.thirtyDayEnergyGeneratedkWh)
    let formattedNetEnergy = formatValue(hasGridData && gridData.kiosk.thirtyDayNetEnergykWh)
    let formattedEnergyPurchased = formatValue(hasGridData && gridData.kiosk.thirtyDayEnergyPurchasedkWh)
    let formattedCO2 = formatValue(hasGridData && gridData.kiosk.thirtyDayAvoidedCo2MetricTons)
    let formattedCoalBurned = formatValue(hasGridData && gridData.kiosk.thirtyDayCo2OffsetPoundsOfCoalBurned)
    let formattedTrees = formatValue(hasGridData && gridData.kiosk.thirtyDayCo2OffsetTreesPlanted)
    let formattedCarMiles = formatValue(hasGridData && gridData.kiosk.thirtyDayCo2OffsetCarMiles)
    let formattedCarKm = formatValue(hasGridData && gridData.kiosk.thirtyDayCo2OffsetCarKilometers)
    
    //Which stats to show
    let shouldShowEnergyConsumed = hasGridData && gridData.kiosk.displayThirtyDayEnergyConsumedkWh
    let shouldShowEnergyGenerated = hasGridData && gridData.kiosk.displayThirtyDayEnergyGeneratedkWh
    let shouldShowNetEnergy = hasGridData && gridData.kiosk.displayThirtyDayNetEnergykWh
    let shouldShowEnergyPurchased = hasGridData && gridData.kiosk.displayThirtyDayEnergyPurchasedkWh
    let shouldShowCO2 = hasGridData && gridData.kiosk.displayThirtyDayAvoidedCo2MetricTons
    let shouldShowTrees = hasGridData && gridData.kiosk.displayThirtyDayCo2OffsetTreesPlanted
    let shouldShowCarMiles = hasGridData && gridData.kiosk.displayThirtyDayCo2OffsetCarMiles
    let shouldShowCarKm = hasGridData && gridData.kiosk.displayThirtyDayCo2OffsetCarKilometers
    let shouldShowCoalBurned = hasGridData && gridData.kiosk.displayThirtyDayCo2OffsetPoundsOfCoalBurned


    useEffect(() => {
        let tempGX = window.location.pathname.split('/')[3]
        setCurrentGX(window.location.pathname.split('/')[3])

        elexity.admin.monitor.graphs.initAuth(authenticatedComponent.generateAuthenticatedHeader())
        elexity.admin.monitor.graphs.init(
            tempGX,
            configuration['backend_host']
                + '/ahiapi/lobbyKiosk/' + tempGX + '/graph/definition',
            configuration['backend_host']
                + '/ahiapi/lobbyKiosk/' + tempGX + '/graph',
            graphConfiguration,
            'livePerformanceGraph',
        )
    }, [])

    useEffect(() => {
        if (currentGXId !== null) {
            if (liveChartRef.current) {
                updateGXData()

                elexity.admin.monitor.graphs.initAuth(authenticatedComponent.generateAuthenticatedHeader())
                elexity.admin.monitor.graphs.init(
                    currentGXId,
                    configuration['backend_host']
                        + '/ahiapi/lobbyKiosk/{entityKey}/graph/definition',
                    configuration['backend_host']
                        + '/ahiapi/lobbyKiosk/{entityKey}/graph',
                    graphConfiguration,
                    'livePerformanceGraph',
                )
            }
        }
    }, [liveChartRef.current])
    
    useEffect(() => {
        if (currentGXData?.kiosk !== undefined) {
            setGridData(currentGXData)
        }

        //Graph data update every 5 seconds
        let interval = setInterval(() => {
            updateGXData()
            setGridData(currentGXData)
        }, 5000)
    
        return () => clearInterval(interval)
    }, [currentGXData])

    //Load initial graphs
    const graphConfiguration = ({
        seriesDefinitions : [
            {name: "Grid Power", color: "#FA974F", type: "spline", opacity: 1, dataGrouping: { approximation: 'high'}},
            {name: "Solar Power", color: "#F5D500", type: "spline", opacity: 1, dataGrouping: { approximation: 'high'}},
            // {name: "HVAC Power", color: "#069BCE", type: "spline", opacity: 1, dataGrouping: { approximation: 'high'}},
            // {name: "EV Charger Power", color: "#0FFDC2", type: "spline", opacity: 1, dataGrouping: { approximation: 'high'}},
            {name: "Battery Power", color: "#02BE7F", type: "spline", opacity: 1, dataGrouping: { approximation: 'high'}},
            {name: "Managed Load", color: "#ADD8E6", type: "column", stacking: 'normal', opacity: .75, dataGrouping: { approximation: 'high'}},
            // {name: "Limit", color: "#324E55", type: "spline", stacking: 'normal', opacity: .75, dataGrouping: { approximation: 'high'}},
            {name: "Unmanaged Load", color: "#CCCCCC", type: "column", stacking: 'normal', opacity: .75, dataGrouping: { approximation: 'high'}},
            // {name: "Total Load", color: "#2FE0E6", type: "spline", opacity: 1, dataGrouping: { approximation: 'high'}}       
        ],

        defaultLiveZoom : 3
    })

    return (<>
        <LobbyKioskPage className="lobby-kiosk">
            <GraphKiosk>
                <div className='kiosk-model'>
                    <h4>Now</h4>
                        <KioskModel 
                            gxPower={hasGridData && gridData.kiosk.gridkW}
                            hasSolar={hasGridData && gridData.kiosk.hasSolar}
                            solarPower={hasGridData && gridData.kiosk.solarkW}
                            hasBattery={hasGridData && gridData.kiosk.hasBattery}
                            batterySoc={hasGridData && gridData.kiosk.batterySoc}
                            batteryDisplaySoc={hasGridData && gridData.kiosk.batteryDisplaySoc}
                            batteryPower={hasGridData && gridData.kiosk.batterykW}
                            hasEv={hasGridData && gridData.kiosk.hasEvCharger}
                            hasEvConnected={hasGridData && gridData.kiosk.hasEvConnected}
                            evPower={hasGridData && gridData.kiosk.evChargerkW}
                            hvacPower={hasGridData && gridData.kiosk.hvackW}
                            otherPower={hasGridData && gridData.kiosk.unmanagedkW}
                        />
                </div>

                <div className='energy-insights-graph'>
                    <h4>Energy Insights - last 3 days</h4>
                        <div 
                            id="livePerformanceGraph" 
                            className="insights-graph-container" 
                            ref={liveChartRef}
                        ></div>
                </div>
            </GraphKiosk>

            <Stats>
                <div className="stats-container">
                    <h4>Last 30 Days</h4>
                    
                    <div className="energy-stat-container">
                        <h6>Energy</h6>
                        
                        <div className="energy-consumed stat-card">
                            <img src={meter} alt="Grid meter icon" />
                            <div className="stats">
                                <p className='value'>{hasGridData && formattedEnergyConsumed} kWh</p>
                                <p className='label'>Consumed</p>
                            </div>
                        </div>

                        <div className="energy-generated stat-card">
                            <img src={solar} alt="Solar panel with sun icon" />
                            <div className="stats">
                                <p className='value'>{hasGridData && formattedEnergyGenerated} kWh</p>
                                <p className='label'>Generated</p>
                            </div>
                        </div>

                        <div className="energy-net stat-card">
                            <img src={bulb} alt="Lit up light bulb icon" />
                            <div className="stats">
                                <p className='value'>{hasGridData && formattedNetEnergy} kWh</p>
                                <p className='label'>Net Energy</p>
                            </div>
                        </div>

                        <div className="energy-purchased stat-card">
                            <img src={energyPurchased} alt="Lit up light bulb icon" />
                            <div className="stats">
                                <p className='value'>{hasGridData && formattedEnergyPurchased} kWh</p>
                                <p className='label'>Purchased</p>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div className="co2-stat-container">
                        <h6>CO<sup>2</sup> Equivalencies</h6>
                        
                        <div className="avoided-co stat-card">
                            <img src={globe} alt="Earth icon" />
                            <div className="stats">
                                <p className='value'>{hasGridData && formattedCO2}</p>
                                <p className='label'>Avoided Tons</p>
                            </div>
                        </div>
                        
                        {shouldShowCarMiles && 
                            <div className="avoided-co stat-card">
                                <img src={carMiles} alt="A line of cars icon" />
                                <div className="stats">
                                    <p className='value'>{hasGridData && formattedCarMiles}</p>
                                    <p className='label'>Car Miles</p>
                                </div>
                            </div>
                        }

                        {shouldShowCarKm && 
                            <div className="avoided-co stat-card">
                                <img src={carMiles} alt="A line of cars icon" />
                                <div className="stats">
                                    <p className='value'>{hasGridData && formattedCarKm}</p>
                                    <p className='label'>Car Kilometers</p>
                                </div>
                            </div>
                        }

                        <div className="energy-purchased stat-card">
                            <img src={trees} alt="three trees icon" />
                            <div className="stats">
                                <p className='value'>{hasGridData && formattedTrees}</p>
                                <p className='label'>Trees Planted</p>
                            </div>
                        </div>

                        <div className="avoided-co stat-card">
                            <img src={coalBurned} alt="A line of cars icon" />
                            <div className="stats">
                                <p className='value'>{hasGridData && formattedCoalBurned}</p>
                                <p className='label'>Pounds of Coal Burned</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Stats>
        </LobbyKioskPage>

        <LobbyFooter>
            <div className="bar"></div>
            <div className="elexity-logo">
                <p>Powered By</p>
                <img src={elexity_logo} alt="" />
            </div>
        </LobbyFooter>
    </>)
}

const LobbyKioskPage = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 30px 30px 0;
    row-gap: 22px;

    h4, h6 {
        color: #02222B;
        margin: 0;
    }

    h4 {
        font-size: 24px;
        font-weight: 800;
        line-height: 22px;
        
        
    }

    h6 {
        font-size: 18px;
        font-weight: 600;
        text-decoration: underline;
        text-transform: uppercase;
        width: 215px;
        sup {
            font-size: 14px;
            line-height: 0;
        }
    }
`

const Stats = styled.section`
    background: white;
    border: none;
    border-radius: 8px;
    box-shadow: rgba(194, 208, 209, .5) 0 0 10px;
    padding: 20px;

    .stats-container {
        align-items: flex-start;
        color: #02222B;
        row-gap: 24px;
        display: flex;
        flex-flow: column;
    }

    hr {
        background: #EDF1F2;
        border: none;
        height: 3px;
        width: 100%;
    }

    .energy-stat-container,
    .co2-stat-container {
        align-items: center;
        display: flex;
        justify-content: flex-start;
        width: 100%;
    }

    .stat-card {
        align-items: center;
        column-gap: 24px;
        display: flex;
        justify-content: flex-start;
        padding: 20px;
        width: 320px;

        img {
            min-width: 75px;
            width: 4vw;
        }

        p {
            margin: 0;
        }
        
        .label {
            font-size: 18px;
            font-weight: 500;
        }
        .value {
            font-size: 28px;
            font-weight: 600;
        }
    }
`

const GraphKiosk = styled.section`
    align-items: center;
    column-gap: 24px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    .kiosk-model {
        background: white;
        border: none;
        border-radius: 8px;
        box-shadow: rgba(194, 208, 209, .5) 0 0 10px;
        display: flex;
        flex-flow: column;
        height: 520px;
        justify-content: space-between;
        padding: 20px;
        width: 40%;
    }

    .kiosk-container {
        border: 0;
        height: 100%;
        padding: 0;
        width: 100%;

        .container .arrow .arrow-container .line {
            height: 78px;
        }

        .container .block {
            width: unset;
        }
    }

    h6 {
        margin-top: 5px; 
    }

    .energy-insights-graph {
        background: rgba(255, 255, 255, 0.5);
        border: none;
        border-radius: 8px;
        box-shadow: rgba(194, 208, 209, .5) 0 0 10px;
        display: flex;
        flex-direction: column;
        height: 540px;
        justify-content: space-between;
        padding: 20px 20px 0;
        width: 60%;
    }

    .insights-graph-container {
        height: 500px;
        width: 100%;

        .loading {
            color: #02222b;
            font-size: 18px;
            font-weight: 600;
        }
    }

    .highcharts-background {
        fill: none;
    }
`

const LobbyFooter = styled.section`
    align-items: center;
    display: flex;
    justify-content: space-between;

    .bar {
        background: #02222B;
        height: 40px;
        transform: skew(-30deg) translateX(-18px);
        width: calc(100% - 300px);
    }

    .elexity-logo {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 300px;

        p {
            margin: 0;
        }

        img {
            height: 30px;
            padding-left: 20px;
            width: 140px;
        }
    }

`

const mapStateToProps = (state) => {
    return {
        campuses: state.campuses,
        currentCampusId: state.currentCampusId,
        currentGXId: state.currentGXId,
        currentCampusData: state.currentCampusData,
        currentGXData: state.currentGXData
    }
}
  
// used by parent class AuthenticatedComponent
const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentGX: (gxId) => {
        dispatch(setCurrentGX(gxId))
        },
        setCurrentGXData: (gxData) => {
        dispatch(setCurrentGXData(gxData))
        }
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(LobbyKiosk))