import React, { useEffect } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import exportingModule from 'highcharts/modules/exporting';
import styled from '@emotion/styled';

import MeterCardContainer from '../components/MeterCardContainer';
import KioskModel from '../components/KioskModel';
import Breadcrumbs from '../components/Breadcrumbs';
import EnergyUtilityMeterDetail from './subsections/EnergyUtilityMeterDetail';
import { setCurrentGX } from '../actions';
import { connect } from 'react-redux';
import WithRouter from '../utilities/WithRouter';

import icon_energy from '../assets/icon_energy.svg';

require('highcharts/modules/accessibility')(Highcharts);
require('highcharts/modules/draggable-points')(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

const Energy = ({ 
    currentCampusData,
    currentCampusId,
    currentBuildingId,
    currentGXId,
    hasSingleGX,
    currentGXData,
    onBuildingChange,
    onGXChange,
    onZoneChange,
    router,
    updateGXData,
    showMobile,
    activePage
}) => {
    exportingModule(Highcharts)
    let currentSelectedMeterData

    useEffect(() => {
        if (currentGXData?.gridInterconnects?.length === 1 
            && currentGXId !== currentGXData.gridInterconnects[0].gridInterconnectDescription.gridInterconnectEntityKey) {
            onGXChange(currentGXData.gridInterconnects[0].gridInterconnectDescription.gridInterconnectEntityKey)
        }
    }, [])

    //Call energy API when campus change
    useEffect(() => {
        updateGXData(currentCampusId)
    }, [currentCampusId])
    
    //When gx data updates, clear graphs and reload
    useEffect(() => {
        if (currentGXData?.gridInterconnects?.length === 1 
            && currentGXId !== currentGXData.gridInterconnects[0].gridInterconnectDescription.gridInterconnectEntityKey) {
            onGXChange(currentGXData.gridInterconnects[0].gridInterconnectDescription.gridInterconnectEntityKey)
        }

        //Graph data update every 5 seconds
        if ((currentGXId === null && !hasSingleGX) || (currentGXId !== null && hasSingleGX)) {
            let interval = setInterval(() => {
                updateGXData(currentCampusId)
                }, 5000)
        
                return () => clearInterval(interval)
        }
    }, [currentGXData])

    const navigateWithValidation = (newPath) => {
        if (router.location.pathname !== newPath) {
            router.navigate(newPath)
        }
    }

    const handleMeterSelect = (selectedMeter) => {
        onGXChange(selectedMeter)
        navigateWithValidation('/energy/' + currentCampusId + '/' + selectedMeter)
    }

    return (
        <EnergySection className={showMobile ? "energy-section mobile" : "energy-section desktop"}>
            {currentCampusData && currentGXData && (!currentGXId || hasSingleGX) ? (
                <div className="utility-meter-main">
                    <div className="header-container">
                        <div className="header">
                            <img src={icon_energy} alt="electricity icon" width="18" height="18" />
                            <h1>Energy</h1>
                        </div>
                    </div>

                    {currentGXData?.gridInterconnects?.map((gx) => (
                        <div 
                            className="meter-row"
                            key={`${gx.gridInterconnectDescription.gridInterconnectEntityKey}-meter-container`}
                        >
                            <div className="meter-name-container">
                                <div className="meter-name">
                                    {gx.gridInterconnectDescription.longGridInterconnectDisplayName ? 
                                        gx.gridInterconnectDescription.longGridInterconnectDisplayName 
                                        : 
                                        "Utility Meter"
                                    }
                                </div>

                                {currentGXData?.gridInterconnects.length > 1 && (
                                    <div 
                                        className="meter-button"
                                        onClick={() => handleMeterSelect(gx.gridInterconnectDescription.gridInterconnectEntityKey)}
                                    >
                                        View Meter
                                    </div>
                                )}
                            </div>

                            <div className={showMobile ? "meter-container mobile" : "meter-container"}>
                                {window.innerWidth <= 1024 && (<>
                                    <MeterCardContainer
                                        currentMeterData={currentSelectedMeterData}
                                        gridPower={gx.kiosk.gridkW}
                                        solarPower={gx.kiosk.solarkW}
                                        batteryPower={gx.kiosk.batterykW}
                                        batterySoc={gx.kiosk.batterySoc}
                                        batterySockWh={gx.kiosk.batterySockWh}
                                        hasSolar={gx.kiosk.hasSolar}
                                        hasBattery={gx.kiosk.hasBattery}
                                        hasEv={gx.kiosk.hasEvCharger}
                                        evPower={gx.kiosk.evChargerkW}
                                        hvacPower={gx.kiosk.hvackW}
                                        otherPower={gx.kiosk.unmanagedkW}
                                    />
                                </>)}

                                <KioskModel
                                    gxPower={gx.kiosk.gridkW}
                                    hasSolar={gx.kiosk.hasSolar}
                                    solarPower={gx.kiosk.solarkW}
                                    hasBattery={gx.kiosk.hasBattery}
                                    batteryDisplaySoc={gx.kiosk.batteryDisplaySoc}
                                    batterySoc={gx.kiosk.batterySoc}
                                    batteryPower={gx.kiosk.batterykW}
                                    batterySockWh={gx.kiosk.batterySockWh}
                                    hasEv={gx.kiosk.hasEvCharger}
                                    hasEvConnected={gx.kiosk.hasEvConnected}
                                    evPower={gx.kiosk.evChargerkW}
                                    hvacPower={gx.kiosk.hvackW}
                                    otherPower={gx.kiosk.unmanagedkW}
                                />
                            </div>

                            {hasSingleGX && (
                                <EnergyUtilityMeterDetail
                                    currentCampusData= {currentCampusData}
                                    currentCampusId={currentCampusId}
                                    currentBuildingId={currentBuildingId}
                                    currentZoneId={null}
                                    currentGXId={currentGXId}
                                    hasSingleGX={hasSingleGX}
                                    currentGXData={currentGXData}
                                    onBuildingChange={onBuildingChange}
                                    onGXChange={onGXChange}
                                    onZoneChange={onZoneChange}
                                    activePage={activePage}
                                    showMobile={showMobile}
                                />
                            )}
                        </div>
                    ))}
                </div>
            ) 
            : 
            (
                <EnergyUtilityMeterDetail
                    currentCampusData= {currentCampusData}
                    currentCampusId={currentCampusId}
                    currentBuildingId={currentBuildingId}
                    currentZoneId={null}
                    currentGXId={currentGXId}
                    hasSingleGX={hasSingleGX}
                    currentGXData={currentGXData}
                    onBuildingChange={onBuildingChange}
                    onGXChange={onGXChange}
                    onZoneChange={onZoneChange}
                    activePage={activePage}
                    showMobile={showMobile}
                />
            )}

            <a
                className="hidden-lobby-kiosk"
                href={`/lobbyKiosk/${currentCampusId}/${currentGXId}`}
            >
                LobbyKiosk
            </a>
        </EnergySection>
    )
}

const EnergySection = styled.section`
    height: 100vh;
    overflow: auto;
    padding-right: 24px;

    .header-container {
        align-items: center;
        background: #f8f9fb;
        display: flex;
        height: 55px;
        position: sticky;
        top: 0;
        /* z-index: 100; */
    }

    a.hidden-lobby-kiosk {
        color: transparent;
    }

    .meter-row {
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;

        &:last-child {
            border: none;
            margin-bottom: 0;
        }

        .meter-name-container {
            align-items: center;
            display: flex;
            justify-content: space-between;
            margin: 0 auto 0 0;
            padding: 8px 0 0;
            width: 100%;

            .meter-name {
                color: #02222B;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                text-align: left;
            }

            .meter-button {
                background: #02222B;
                border-radius: 8px;
                color: white;
                cursor: pointer;
                font-size: 14px;
                font-weight: 600;
                padding: 10px 0;
                text-align: center;
                transition: ease-in all 150ms;
                
                width: 180px;

                &:hover {
                    background: #02222B;
                    transition: ease-in all 150ms;
                }
            }
        }

        .meter-container {
            align-items: center;
            background: white;
            border-radius: 8px;
            box-shadow: 0px 0px 10px rgba(194, 208, 209, 0.5);
            display: flex;
            justify-content: center;
            margin: 12px 0 60px;
            padding: 40px;
            width: calc(100% - 80px);

            &:last-child {
                border-bottom: none;
                margin-bottom: 0;
            }

            /*MOBILE*/
            &.mobile {
                flex-flow: column;

                .kiosk-container hr {
                    width: calc(100% - 57px);
                }
            }
        }
    }

    @media screen and (max-width: 1024px) {
        .meter-row .meter-container {
            justify-content: space-between;
            padding: 24px;
            width: calc(100% - 48px);
        }
    }
`

const mapStateToProps = (state) => {
    return {
        campuses: state.campuses,
        currentCampusId: state.currentCampusId,
        currentGXId: state.currentGXId,
        currentCampusData: state.currentCampusData,
        currentGXData: state.currentGXData
    }
}
  
// used by parent class AuthenticatedComponent
const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentGX: (gxId) => {
        dispatch(setCurrentGX(gxId))
        },
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(Energy))