import { createStore } from "redux"

const initialState = {
  "campuses": [],
  "currentCampusId": null,
  "currentBuildingId": null,
  "currentZoneId": null,
  "currentGXId": null,
  "currentCampusData": {},
  "currentGXData": {},
  "currentEVData": {},
  "currentBatteryData": {},
  "currentZoneData": {},
  "currentUserData": {},
}

const elexityStore = (state = initialState, action) => {
  switch (action.type) {
    case "SAVE_CAMPUSES":
      const newState = {
        ...state,
        campuses: action.data
      }
      
      if (newState.campuses.length > 0 && state.currentCampusId === null) {
        newState.currentCampusId = newState.campuses[0].campusEntityKey
      }

      return newState

    case "SET_CURRENT_CAMPUS":
      let currentCampusId

      currentCampusId = action.campusId
      
      return {
        ...state,
        currentCampusId
      }

    case "SET_CURRENT_BUILDING":
      let currentBuildingId = action.buildingId

      return {
        ...state,
        currentBuildingId
      }

    case "SET_CURRENT_ZONE":
      let currentZoneId = action.zoneId

      return {
        ...state,
        currentZoneId
      } 

    case "SET_CURRENT_GX":
    let currentGXId = action.gxId

    return {
      ...state,
      currentGXId
    } 

    case "SET_CURRENT_GXDATA":
    let currentGXData = action.gxData

    return {
      ...state,
      currentGXData
    }

    case "SET_CURRENT_EV_DATA":
    let currentEVData = action.evData

    return {
      ...state,
      currentEVData
    }

    case "SET_CURRENT_BATTERY_DATA":
      let currentBatteryData = action.batteryData
  
      return {
        ...state,
        currentBatteryData
      }

    case "SET_CURRENT_ZONE_DATA":
    let currentZoneData = action.zoneData

    return {
      ...state,
      currentZoneData
    }

    case "UPDATE_CURRENT_CAMPUSDATA":
      let campusId = action.campusData.campusDescription.campusEntityKey
      let mobileCampusData

      if (campusId === null) {
        mobileCampusData = state.campuses[0]
      } else {
        mobileCampusData = action.campusData
      }

      Object.assign(state.currentCampusData, action.campusData)
      return {
        ...state,
        currentCampusData: {...state.currentCampusData}
      }

    case "SET_USER_DATA":
      Object.assign(state.currentUserData, action.userData)
      return {
        ...state,
        currentUserData: {...state.currentUserData}
      }
      
    default:
      return state
  }
}

const store = createStore(elexityStore)
export default store