import React, { useState } from "react";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import styled from "@emotion/styled";

import DatePicker from "react-datepicker";
import getResponse from "../../utilities/FetchHelper";
import configuration from "../../config";
import Modal from "../Modal";
import icon_event from "../../assets/icon_event.svg";
import LowerUpperMode from "./LowerUpperMode";
import StartEndTime from "./StartEndTime";

const ComfortModalScheduleEvent = ({
  clearModal,
  zoneData,
  showMobile,
  updateZoneData,
  setProcessing,
  errorMessage,
  setErrorMessage,
  errorMessageRef
}) => {
  const newDate = new Date()
  let year = newDate.toLocaleString("default", { year: "numeric" });
  let month = newDate.toLocaleString("default", { month: "2-digit" });
  let day = newDate.toLocaleString("default", { day: "2-digit" });
  let formattedDate = year + "-" + month + "-" + day;

  const [startDate, setStartDate] = useState(newDate)
  const [postData, setPostData] = useState({
    eventName: `New Event: ${formattedDate}`,
    date: formattedDate,
    startTime: '06:00',
    endTime: '14:30',
    lowerBound: 68,
    upperBound: 75,
    blockCooling: false,
    blockHeating: false,
  })
  
  // const currentTimezone = moment().tz(moment.tz.guess()).format('z')
  // let timeOptions = []

  // for (let i=0; i < 24; i++) {
  //   for (let j=0; j < 4; j++) {
  //     timeOptions.push(`${i < 10 ? `0${i}` : i}:${j === 0 ? `00` : 15*j}`)
  //   }
  // }

  const handleChange = (index = null, name, value) => {
    //lowerbound upperbound mode
    if (name === 'lowerBound') {
      setPostData(
        prevState => ({
          ...prevState,
          lowerBound: value
        })
      )
    } else if (name === 'upperBound') {
      setPostData(
        prevState => ({
          ...prevState,
          upperBound: value
        })
      )
    } else if (name === 'heat') {
      setPostData(
        prevState => ({
        ...prevState,
        blockCooling: true,
        blockHeating: false,
        })
      )
    } else if (name === 'cool') {
      setPostData(
        prevState => ({
        ...prevState,
        blockCooling: false,
        blockHeating: true,
        })
      )
    } else if (name === 'fan') {
      setPostData(
        prevState => ({
        ...prevState,
        blockCooling: true,
        blockHeating: true,
        })
      )
    } else if (name === 'auto') {
      setPostData(
        prevState => ({
        ...prevState,
        blockCooling: false,
        blockHeating: false,
        })
      )
    }

    //start end time
    if (name === 'startTime') {
      setPostData(
        prevState => ({
        ...prevState,
        startTime: value
        })
      )
    } else if (name === 'endTime') {
      setPostData(
        prevState => ({
        ...prevState,
        endTime: value
        })
      )
    }
  }

  const handleDateSelect = (selectedDate) => {
    let newDay = selectedDate.getDate() < 10 ? `0${selectedDate.getDate()}` : selectedDate.getDate()
    let indexMonth = selectedDate.getMonth() + 1
    let newMonth = indexMonth < 10 ? `0${indexMonth}` : indexMonth
    
    setStartDate(selectedDate)
    setPostData(
      prevState => ({
        ...prevState,
        date: selectedDate.getFullYear()
        + '-' +
        newMonth
        + '-' +
        newDay
      })
    )
  }

  const saveScheduleEventSettings = () => {
    setProcessing(true)

    const encodeGetParams = p => 
      Object.entries(p).map(item => item.map(encodeURIComponent).join("=")).join("&")
 
    getResponse(configuration["backend_host"] + "/ahiapi/"
    + zoneData.thermalZoneEntityKey
    + "/thermalZone/event",
    false,
    "POST",
    encodeGetParams(postData),
    updateZoneData,
    setProcessing,
    errorMessage,
    setErrorMessage,
    errorMessageRef
    )
  }

  return (
    <Modal
      modalTitle={"Schedule Event"}
      modalImage={icon_event}
      clearModal={clearModal}
      updateSettings={saveScheduleEventSettings}
      showMobile={showMobile}
      renderModalContent={(
        <StyledScheduleEventDiv className={showMobile ? "modal-homepage-zone-settings" : "modal-homepage-zone-settings desktop"}>
          <div className="home-schedule-event-fields-container">
            <div className="frm-field-container">
              <label>
                Event Name
                <div>
                  <input
                    type="text"
                    name="event_name"
                    maxLength="30"
                    placeholder={`New Event: ${formattedDate}`}
                    onChange={(e) => setPostData(
                      prevState => ({
                        ...prevState,
                        eventName: e.target.value.replace(/\s/g, '+')
                      })
                    )}
                    />
                </div>
              </label>
            </div>
            <div className="frm-field-container">
              <label>
                Date
                <div className="date-input-container">
                  <DatePicker 
                    selected={startDate}
                    onChange={(date) => handleDateSelect(date)}
                  />
                </div>
              </label>
            </div>
            <StartEndTime handleChange={handleChange}/>

            <LowerUpperMode 
              handleChange={handleChange}
            />
          </div>
        </StyledScheduleEventDiv>
      )}
    /> 
  )
}

const StyledScheduleEventDiv = styled.div`
  .home-schedule-event-fields-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    column-gap: 24px;

    .frm-field-container,
    .time-container {
      padding-bottom: 24px;
    }

    .frm-field-container,
    .frm-field-container input {
      width: 100%;
      z-index: 10;
    }

    .frm-field-container label > div {
      padding-top: 12px;
    }

    .time-container,
    .lowerUpperMode-container {
      height: 173px;
    }

    .time-container {
      margin-right: 24px;
      width: 50%;

      .start-time-container {
        padding-bottom: 24px;
      }
    }

    .lowerUpperMode-container {
      width: 35%;

      .mode-container {
        padding: 0;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .home-schedule-event-fields-container {
      .time-container,
      .lowerUpperMode-container {
        margin-right: 0;
        width: 100%;
      }
    }
  }
`

export default ComfortModalScheduleEvent