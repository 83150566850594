import React, { useEffect, useState, useRef } from "react";
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import exportingModule from 'highcharts/modules/exporting';
import styled from "@emotion/styled";
import {connect} from "react-redux";

import WithRouter from "../../utilities/WithRouter";
import AuthenticatedComponent from "../../utilities/AuthenticatedComponent";
import configuration from "../../config";
import { elexity } from "../../utilities/BatteryAllocationGraphHelper";
import { setCurrentCampus } from "../../actions";
import icon_edit from "../../assets/icon_edit.svg";
import icon_info from "../../assets/icon_info.svg"

require('highcharts/modules/accessibility')(Highcharts)
require('highcharts/modules/draggable-points')(Highcharts)
require("highcharts/modules/exporting")(Highcharts)
require("highcharts/modules/export-data")(Highcharts)

const BatteryContent = ({
  currentCampusId,
  currentBatteryData,
  openBatteryModal,
  showMobile,
}) => {
  exportingModule(Highcharts)

  const batteryAllocationRef = useRef(null);
  const batteryHistoryRef = useRef(null);
  const authenticatedComponent = new AuthenticatedComponent;

  useEffect(() => {
    if (currentBatteryData) {
      currentBatteryData.gridInterconnects.map((gx) => {
        elexity.gx.battery.init(
          currentBatteryData,
          gx.gridInterconnectEntityKey,
          configuration['backend_host']
          + '/admin/monitor/' + `${gx.gridInterconnectEntityKey}`
          + '/gxbattery/graphData/history',
          authenticatedComponent.generateAuthenticatedHeader(),
          showMobile
        )
      })
    }
  }, [])

  useEffect(() => {
    elexity.gx.battery.allocationGraph = {}
  }, [currentCampusId])

  useEffect(() => {
    if (currentBatteryData) {
      let gxId;

      currentBatteryData.gridInterconnects.map((gx) => {
        gxId = gx.gridInterconnectEntityKey
      })
  
      if (
        currentBatteryData.gridInterconnects && 
        batteryAllocationRef.current && 
        batteryHistoryRef.current) 
      {
        let interval = setInterval(() => {
          elexity.gx.battery.renderGraphs(
            currentBatteryData,
            configuration['backend_host']
            + '/admin/monitor/' + `${gxId}`
            + '/gxbattery/graphData/history',
            authenticatedComponent.generateAuthenticatedHeader(),
            showMobile
          )
        }, 5000)
  
        return () => clearInterval(interval)
      }
    }
  }, [currentBatteryData])

  return (
    <StyledBattery>
      {currentBatteryData && currentBatteryData.gridInterconnects && (<>
        {currentBatteryData.gridInterconnects.map((item, index) => (
        <React.Fragment key={`section-${index}`}>
          <div className="gx-container">
            <h3 className="gx-name">{item.gridInterconnectEntityKey}</h3>

            <div className="totals-graph-container">
              <div className="stats-graph-container">
                <div className="battery-totals">
                  <p className="subhead total">All Battery Totals</p>
                  
                  <div className="battery-total-detail soc">
                    <div className="battery-soc-graphic">
                      <img 
                        className="icon" 
                        src={require(`../../assets/kiosk/battery${item.batteryDisplaySoc}.svg`)} 
                        alt="Current Battery SOC % graphic" width="42" height="42"
                      />
                    </div>

                    <div className="battery-soc-container">
                      <p className="category total">Battery SOC</p>
                      <p className="value total">{`${item.socPercent}% / ${item.sockWh} kWh`}</p>
                    </div>
                  </div>

                  <div className="battery-total-detail">
                    <p className="category total">Current Power</p>
                    <p className="value total">{`${item.realPowerkW} kW`}</p>
                  </div>

                  <hr />

                  <div className="battery-total-detail">
                    <p className="category total">Capacity</p>
                    <p className="value total">{`${item.capacitykWh} kWh`}</p>
                  </div>

                  <div className="battery-total-detail">
                    <p className="category total">Max Charge</p>
                    <p className="value total">{`${item.chargeCapacitykW} kW`}</p>
                  </div>

                  <div className="battery-total-detail">
                    <p className="category total">Max Discharge</p>
                    <p className="value total">{`${item.dischargeCapacitykW} kW`}</p>
                  </div>
                </div>

                <div className="battery-allocation-container">
                  <div className="allocation-header-container">
                    <div className="allocation-header">
                      <p className="subhead">Battery Allocation</p>

                      <div className="tooltip">
                        <img src={icon_info} alt="More information about battery allocation" width="15" height="15" />
                        <span className="tooltip-text">Hi this is a tooltip</span>
                      </div>
                    </div>

                    <div className="outage-risk">
                      <div className="title-edit">
                        <p className="group-subhead">Outage Risk</p>
                        <div onClick={() => openBatteryModal("edit-battery", item.gridInterconnectEntityKey)}>
                          <img src={icon_edit} alt="Pencil icon to edit risk settings" width="15" height="15" />
                        </div>
                      </div>

                      <div className="outage-setting">
                        <div className={`${item.gridOutageRisk === "None" ? "active" : ""} pill risk none`}>
                          <p>None</p>
                        </div>

                        <div className={`${item.gridOutageRisk === "Low" ? "active" : ""} pill risk low`}>
                          <p>Low</p>
                        </div>

                        <div className={`${item.gridOutageRisk === "Medium" ? "active" : ""} pill risk medium`}>
                          <p>Medium</p>
                        </div>

                        <div className={`${item.gridOutageRisk === "High" ? "active" : ""} pill risk high`}>
                          <p>High</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {!showMobile && (<>
                    <div
                      id={`allocation-graph-${item.gridInterconnectEntityKey}`}
                      className="allocation-graph"
                      ref={batteryAllocationRef}
                    ></div>
                  </>)}

                  {showMobile && (<>
                    <table>
                      <tbody>
                        <tr>
                          <th className="subhead">Category</th>
                          <th className="subhead">Allocated</th>
                          <th className="subhead">Available</th>
                        </tr>

                        {item.allocations.map((entry, index) => (
                          <React.Fragment key={`${index}-entries`}>
                            <tr>
                              <td className="category">{entry.category}</td>
                              <td className="allocated">{entry.allocated}</td>
                              <td className="available">{entry.available}</td>
                            </tr>
                          </React.Fragment>))
                        }
                      </tbody>
                    </table>
                  </>)}
                </div>
              </div>

              <hr />
              
              <div
                  id={`history-graph-${item.gridInterconnectEntityKey}`}
                  className="history-graph"
                  ref={batteryHistoryRef}
                ></div>
            </div>

            {item.acBatteries.map((battery, index) => (
              <React.Fragment key={`battery-${index}`}>
                <div className="battery-container">
                  <div className="name-battery-container">
                    <p className="subhead">{battery.entityKey}</p>

                    <div className="battery-soc-graphic">
                      <img 
                        className="icon" 
                        src={require(`../../assets/kiosk/battery${item.batteryDisplaySoc}.svg`)} 
                        alt="Current Battery SOC % graphic" width="80" height="80"
                      />
                    </div>
                  </div>

                  <div className="charging-container">
                    <p className="group-subhead">Charging</p>

                    <div className="all-categories">
                      <div className="categories">
                        <p className="category">Battery SOC</p>
                        <p className="value">{`${battery.socPercentage}% / ${battery.sockWh}kWh`}</p>
                      </div>

                      <div className="categories">
                        <p className="category">Current Power</p>
                        <p className="value">{`${battery.current}kW`}</p>
                      </div>

                      <div className="categories">
                        <p className="category">Capacity</p>
                        <p className="value">{`${battery.capacitykWh}kWh`}</p>
                      </div>

                      <div className="categories">
                        <p className="category">Max Charge</p>
                        <p className="value">{`${battery.chargeCapacitykW}kW`}</p>
                      </div>

                      <div className="categories">
                        <p className="category">Max Discharge</p>
                        <p className="value">{`${battery.dischargeCapacitykW}kW`}</p>
                      </div>
                    </div>
                  </div>

                  <div className="cell-container">
                    <div className="cell-temp">
                      <p className="group-subhead">Cell Temp</p>

                      <div className="category-container">
                        <div className="pill cell max">
                          <p className="category">Max</p>
                          <p className="value">
                            {battery.maxCellTemperature === null ? 
                            (`--`)
                            :
                            (`${battery.maxCellTemperature}° C`)}
                          </p>
                        </div>

                        <div className="pill cell avg">
                          <p className="category">Avg</p>
                          <p className="value">
                            {battery.maxCellTemperature + battery.minCellTemperature / 2 === 'NaN' ? 
                            (`--`)
                            :
                            (`${battery.maxCellTemperature + battery.minCellTemperature / 2}° C`)}
                          </p>
                        </div>

                        <div className="pill cell min">
                          <p className="category">Min</p>
                          <p className="value">
                            {battery.minCellTemperature === null ? 
                            (`--`)
                            :
                            (`${battery.minCellTemperature}° C`)}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="cell-volt">
                      <p className="group-subhead">Cell Volt</p>

                      <div className="category-container">
                        <div className="pill cell max">
                          <p className="category">Max</p>
                          <p className="value">
                            {battery.maxCellVoltage === null ? 
                            (`--`)
                            :
                            (`${battery.maxCellVoltage} V`)}
                          </p>
                        </div>

                        <div className="pill cell avg">
                          <p className="category">Avg</p>
                          <p className="value">
                            {battery.maxCellVoltage + battery.minCellVoltage / 2 === 'NaN' ? 
                            (`--`)
                            :
                            (`${battery.maxCellVoltage + battery.minCellVoltage / 2} V`)}
                          </p>
                        </div>

                        <div className="pill cell min">
                          <p className="category">Min</p>
                          <p className="value">
                            {battery.minCellVoltage === null ? 
                            (`--`)
                            :
                            (`${battery.minCellVoltage} V`)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))}

            
          </div>

        </React.Fragment>))}
        
      </>)}
    </StyledBattery>
  )
}

const StyledBattery = styled.div`
  .gx-container {
    display: flex;
    flex-flow: column;
    row-gap: 24px;
    margin-bottom: 24px;

    h3 {
      font-size: 18px;
      font-weight: 600;
      margin: 0;

      &.gx-name {
        font-size: 16px;
        font-weight: 600;
      }
    }

    p {
      margin: 0;

      &.subhead,
      &.group-subhead,
      &.value {
        font-weight: 600;
      }

      &.subhead {
        font-size: 18px;
        margin: 0;
        &.total {
          font-weight: 600;
          margin: 0 0 24px;
        }
      }

      &.group-subhead {
        font-size: 14px;
        margin: 0;
      }

      &.category {
        font-weight: 500;
        &.total {
          color: #324E55;
          font-size: 14px;
        }
      }

      &.value {
        &.total {
          font-size: 24px;
        }
      }
    }

    //PILL TREATMENT RISK AND CELLS
    .pill {
      border: none;
      border-radius: 50px;
      margin: 0;
      padding: 6px 8px;

      p {
        font-size: 12px;
      }

      &.risk {
        background: rgba(146, 165, 168, 0.15);
        color: rgba(2, 34, 43, 0.5);
        text-align: center;
        width: 50px;

        &.active {
          font-weight: 600;

          &.none {
            background: rgba(146, 165, 168, 0.3);
            color: #02222B;
          }

          &.low {
            background: rgba(0, 220, 146, 0.3);
            color: #006140;
          }

          &.medium {
            background: rgba(250, 151, 79, 0.3);
            color: #863F0B;
          }

          &.high {
            background: rgba(249, 97, 56, 0.3);
            color: #7E230A;
          }
        }
      }

      &.cell {
        color: #023446;
        display: flex;
        justify-content: space-between;
        width: 75px;

        &.max {
          background: rgba(128, 203, 228, 0.7);
        }
        &.avg {
          background: rgba(128, 203, 228, 0.4);
        }
        &.min {
          background: rgba(128, 203, 228, 0.2);
        }
      }
    }
    //PILL TREATMENT RISK AND CELLS

    .totals-graph-container,
    .battery-container {
      background: white;
      border-radius: 12px;
      box-shadow: 0 0 10px rgba(237, 241, 242, 0.5);
      display: flex;
      padding: 20px 24px 24px;
    }

    .totals-graph-container {
      flex-flow: column;
      row-gap: 24px;

      hr {
        background: #C2D0D1;
        border: none;
        border-radius: 50px;
        height: 2px;
        margin: 0;
        width: 100%;
      }

      .stats-graph-container {
        column-gap: 24px;
        display: flex;
        justify-content: space-between;
        .battery-totals {
          display: flex;
          flex-flow: column;
          row-gap: 12px;
          width: clamp(240px, 30%, 300px);

          hr {
            width: 80%;
          }

          .battery-total-detail {
            display: flex;
            flex-flow: column;
            justify-content: space-between;
            row-gap: 6px;

            &.soc {
              flex-flow: row;
              justify-content: flex-start;

              & img {
                margin-left: -8px;
              }
            }
          }
        }

        .history-graph {
          width: 100%;
        }

        .battery-allocation-container {
          display: flex;
          flex-flow: column;
          row-gap: 12px;
          width: 70%;

          .allocation-graph {
            height: 100%;
          }

          .allocation-header-container {
            display: flex;
            flex-flow: wrap;
            justify-content: space-between;
            row-gap: 12px;

            .allocation-header {
              display: flex;
              height: 18px;
              justify-content: space-between;
              width: 142px;

              p.subhead {
                font-size: 14px;
              }

              .tooltip {
                position: relative;
                display: inline-block;

                .tooltip-text {
                  background-color: white;
                  border-radius: 8px;
                  bottom: -30px;
                  box-shadow: 0 4px 10px rgba(146, 165, 168, 0.35);
                  color: #02222B;
                  left: 50%;
                  margin-left: -60px;
                  opacity: 0;
                  padding: 6px;
                  position: absolute;
                  text-align: center;
                  transition: opacity 0.3s;
                  visibility: hidden;
                  width: 120px;
                  z-index: 1;
                }

                .tooltip-text::after {
                  content: "";
                  left: 50%;
                  margin-left: -5px;
                  position: absolute;
                  top: 100%;
                }

                &:hover .tooltip-text {
                  visibility: visible;
                  opacity: 1;
                }
              }
            }

            .outage-risk {
              display: flex;
              flex-flow: column;
              row-gap: 12px;
              width: 300px;

              .title-edit {
                display: flex;
                justify-content: space-between;

                img {
                  cursor: pointer;
                }
              }

              .outage-setting {
                display: flex;
                justify-content: space-between;
              }
            }
          }

          table {
            border-collapse: collapse;
            
            text-align: center;
            width: 100%;

            th.subhead {
              border-bottom: 2px solid #C2D0D1;
              font-size: 14px;
              font-weight: 700;
            }

            td {
              font-size: 12px;

              &.category {
                font-weight: 600;
              }
            }

            th:nth-of-type(1), td.category {
              padding: 10px 2px;
              text-align: left;
            }
          }
        }
      }
    }

    .battery-container {
      align-items: center;
      column-gap: 24px;
      justify-content: space-between;
      row-gap: 12px;

      .name-battery-container {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 30%;

        p.subhead {
          max-width: 200px;
          width: 30vw;
        }
      }

      .charging-container,
      .categories,
      .cell-container,
      .cell-temp,
      .cell-volt {
        display: flex;
        flex-flow: column;
        row-gap: 12px;
      }

      .charging-container {
        width: 40%;
        .all-categories {
          column-gap: 12px;
          display: flex;
          flex-flow: row wrap;
          row-gap: 12px;

          .categories {
            row-gap: 6px;
            width: 130px;
            p.category {
              color: #324E55;
              font-weight: 500;
            }
            p.value {
              font-size: 16px;
            }
          }
        }
      }

      .cell-container {
        column-gap: 24px;
        flex-flow:  wrap row;
        width: 30%;

        .category-container {
          column-gap: 12px;
          display: flex;
          flex-flow: wrap;
          row-gap: 12px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .gx-container {
      p.value.total {
        font-size: 18px;
      }

      .totals-graph-container .stats-graph-container .battery-allocation-container {
        width: 100%;
        
        .allocation-header-container .outage-risk {
          width: 100%;
        }
      } 

      .battery-container {
        flex-flow: wrap;
        row-gap: 24px;

        .name-battery-container,
        .charging-container,
        .cell-container {
          width: 100%
        }

        .name-battery-container p.subhead {
          max-width: unset;
          width: 40vw;
        }

        & > div {
          width: 100%;
        }

        .charging-container {
          max-width: unset;
        }

        p.subhead {
          max-width: unset;
          width: 100%;
        }
      }
    } 
  }

  @media screen and (max-width: 500px) {
    .gx-container {
      .totals-graph-container {
        .stats-graph-container {
          flex-flow: wrap;
          row-gap: 24px;
          .battery-totals {
            width: 100%;

            .battery-total-detail, 
            .battery-total-detail.soc {
              justify-content: center;
              text-align: center;
            }
          }

          .battery-allocation-container .allocation-header-container .outage-risk {
            width: 100%;

            .outage-setting {
              column-gap: 4px;
                .pill {
                
                width: 15vw;
                p {
                  font-size: 10px;
                }
              }
            }
          }
        }
      }

      .battery-container {
        .charging-container {
          text-align: center;
          .all-categories {
            .categories {
              width: 100%;
            }
          }
        }
      }  
    } 
  }
`

const mapStateToProps = (state) => {
  return {
    currentCampusId: state.currentCampusId,
    currentCampusData: state.currentCampusData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentCampus: (campusId) => {
        dispatch(setCurrentCampus(campusId))
    }
  }
}
  
  export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(BatteryContent))