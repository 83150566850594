import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import {connect} from "react-redux";

import WithRouter from "../../utilities/WithRouter";
import { setCurrentCampus } from "../../actions";

import icon_car from "../../assets/icon_car.svg";
import icon_edit from "../../assets/icon_edit.svg";
import icon_trash from "../../assets/icon_trash.svg";
import icon_add from "../../assets/icon_add-item.svg";
import icon_close from "../../assets/icon_close.svg";

const EVContent = ({ 
  currentEVData, 
  showMobile, 
  openEVModal, 
  powerDecisions
}) => {
  function formatTime(time) {
    let newTime = time.split(" ").slice(1).join(" ")

    let hours = newTime.substring(0, newTime.indexOf(":"))
    hours = hours < 10 ? '0' + hours : hours

    let minutes = newTime.substring(newTime.indexOf(":") + 1)

    const formattedHours = hours % 12 === 0 ? 12 : hours % 12
    const ampm = hours >= 12 ? 'PM' : 'AM'
    
    return `${formattedHours}:${minutes} ${ampm}`
  }

  return (<>
    {currentEVData && currentEVData.gridInterconnectEvChargers && (<>
      {currentEVData.gridInterconnectEvChargers.map((item, index) => (<React.Fragment key={`section-${index}`}>
        <StyledHeader>
          <h3>{item.gridInterconnectShortDescription !== '' ? 
            item.gridInterconnectShortDescription 
            : 
            item.gridInterconnectEntityKey}
          </h3>

          <div className="add-group">
              <button onClick={() => openEVModal("edit-ev", "", "", item.gridInterconnectEntityKey)}>
                  <img src={icon_add} alt="plus icon" width="15" height="15" />
                  Add Group
              </button>
          </div>
        </StyledHeader>
        
        {item.evChargerGroups.map((group, index) => (<React.Fragment key={`group-${index}`}>
          <StyledEV className="group-container">
            <div className="group-header">
              <h3>{group.evChargerGroupName}</h3>

              <div className="group-edit">
                <div className="edit-button" onClick={() => openEVModal("edit-ev", group.evChargerGroupId, "", item.gridInterconnectEntityKey)}>
                  <img src={icon_edit} alt="pencil and paper edit icon" className="edit" width="15" height="15" />
                </div>
                <div className="delete-button" onClick={() => openEVModal("confirm", group.evChargerGroupId, "delete", item.gridInterconnectEntityKey)}>
                  <img src={icon_trash} alt="trash can delete icon" className="delete" width="15" height="15" />
                </div>
              </div>
            </div>

            <div className="group-contents">
              <div className="group-devices">
                <h4>Devices</h4>
                
                <div className="group-item">
                  <table>
                    <colgroup span="2"></colgroup>
                    <tbody>
                      <tr>
                        <td rowSpan="2"></td>
                        <th className="capacity" colSpan="2" scope="colgroup">Capacity</th>
                      </tr>
                      <tr>
                        <th className="subhead subcol" scope="col">Max</th>
                        <th className="subhead subcol" scope="col">Current</th>
                        <th className="subhead" scope="col">Measured</th>
                      </tr>
                      {group.evChargers.map((charger, index) => (<React.Fragment key={`charger-${index}`}>
                        <tr>
                          <th className="name">
                            <img src={icon_car} alt="car icon" width="20" height="20"/>
                              {charger.shortDescription}
                          </th>
                          <td className="subcol">{(charger.maxCapacityPowerkW).toFixed(2)} kW</td>
                          <td className="subcol">{(charger.currentCapacityPowerkW).toFixed(2)} kW</td>
                          <td>{(charger.realPowerkW).toFixed(2)} kW</td>
                        </tr>
                      </React.Fragment>))}

                      <tr className="total">
                        <th className="total">Total</th>
                        <td className="subcol">{(group.maxPowerCapacitykW).toFixed(2)} kW</td>
                        <td className="subcol">{(group.currentPowerCapacitykW).toFixed(2)} kW</td>
                        <td>{(group.realPowerkW).toFixed(2)} kW</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="group-limit-override">
                <div className="group-limits">
                  <h4>Limits</h4>
                  <div className="limits-container">
                    {Object.entries(powerDecisions).map(([key, value], index) => (<React.Fragment key={`limit-${index}`}>
                      <div className="limit-container">
                        <div className={group.currentPowerDecision === key ? "limit active" : "limit"}>
                          <p className="limit-name">{key}</p>
                          <p>{value}%</p>
                        </div>
                      </div>
                    </React.Fragment>))}
                  </div>
                </div>

                <div className="override">
                  <h4>Override</h4>
                  {group.currentPowerDecision === "Override" ? 
                    (<>
                      <div className="cancel-override">
                        <p>Active: {group.currentPowerCapacityPercent}% until {formatTime(group.overrideExpires)}</p>
                        <div className="cancel-button">
                          <button className="cancel" onClick={() => openEVModal("confirm", group.evChargerGroupId, "cancel-override", item.gridInterconnectEntityKey)}>Cancel Override</button>
                          <img src={icon_close} alt="x icon" className="edit" width="20" height="20" />
                        </div>
                      </div>
                    </>) 
                    : 
                    (<>
                      <button className="override" onClick={() => openEVModal("override", group.evChargerGroupId, "", item.gridInterconnectEntityKey)}>Set Override</button>
                    </>)
                  }
                </div>
              </div>
            </div>
          </StyledEV>
        </React.Fragment>))}
      </React.Fragment>))}
    </>)}
  </>)
}

const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
    
    h3 {
      font-weight: 600;
      margin: 0;
    }
    
    .add-group {
      
      padding-bottom: 24px;
      button {
          align-items: center;
          background: #C2D0D1;
          border: none;
          border-radius: 8px;
          color: #02222B;
          cursor: pointer;
          display: flex;
          font-size: 14px;
          font-weight: 600;
          justify-content: space-evenly;
          padding: 6px 10px;
          transition: all ease-in-out 250ms;
          width: 128px;
          &:hover {
            filter: brightness(0.85);
            transition: all ease-in-out 250ms;
          }
      }
    }
`

const StyledEV = styled.div`
  background: white;
  border-radius: 12px;
  box-shadow: 0 0 10px rgba(237, 241, 242, 0.5);
  display: flex;
  flex-flow: column;
  margin-bottom: 24px;
  padding: 20px 24px 24px;
  row-gap: 12px;

  .group-header {
    align-items: center;
    display: flex;
    justify-content: space-between;

    h3 {
      font-size: 18px;
      margin: 0;
    }

    .group-edit {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 38px;

      img {
        cursor: pointer;
      }
    }
  }

  .group-contents {
    display: flex;
    justify-content: space-between;

    h4 {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 12px;
    }

    .group-devices {
      border-right: 2px solid #C2D0D1;
      padding-right: 24px;
      width: 50%;

      .group-item {
        align-items: center;
        display: flex;
        justify-content: space-between;

        table {
          border-spacing: 0;
          font-size: 14px;
          text-align: left;
          width: 100%;
          th, td {
            border-bottom: none;
            text-align: center;
            padding: 6px 0;
          }
          th.capacity {
            background: rgba(194, 208, 209, 0.5);
            font-weight: 500;
          }
          tr th.subcol,
          tr td.subcol {
            background: rgba(194, 208, 209, 0.3);
          }
          th.subhead {
            font-style: italic;
            font-weight: 500;
          }
          th.name {
            align-items: center;
            display: flex;
          }
          th.name,
          th.total {
            text-align: left;
            width: 180px;
          }
          tr.total th,
          tr.total td {
            border-top: 1px solid #C2D0D1;
          }
          img {
            margin-right: 6px;
          }
        }
      }
    }

    .group-limit-override {
      display: flex;
      flex-flow: column;
      row-gap: 12px;
      width: 45%;

      .group-limits {
        display: flex;
        flex-flow: column;

        .limits-container {
          align-items: center;
          column-gap: 12px;
          display: flex;
          flex-flow: row wrap;
          justify-content: flex-start;
          row-gap: 12px;

          .limit {
            align-items: center;
            background: rgba(146, 165, 168, 0.3);
            border: 3px solid transparent;
            border-radius: 50px;
            display: flex;
            font-size: 12px;
            font-weight: 600;
            justify-content: space-between;
            opacity: 0.7;
            padding: 6px;
            text-align: center;
            width: 100px;

            p {
              margin: 0;
            }

            .limit-name {
              font-size: 11px;
              font-weight: 500;
            }

            &.active {
              background: rgba(0, 227, 171, 0.3);
              color: #003D2E;
              opacity: 1;
            }
          }
        }
      }

      .override {
        display: flex;
        flex-flow: column;
        font-size: 14px;
        font-weight: 600;

        p {
          margin-top: 0;
        }

        button {
          border: none;
          cursor: pointer;
          
          &.override {
            background: #02222B;
            border-radius: 8px;
            color: white;
            font-weight: 600;
            padding: 8px 20px;
            max-width: 126px;
          }

          &.cancel {
            background: transparent;
            color: #02222B;
            font-weight: 500;
            line-height: 17px;
            padding: 0;
            text-decoration: underline;
          }
        }

        .cancel-override, 
        .cancel-button {
          align-items: center;
          display: flex;
          justify-content: space-between;
        }

        .cancel-override {
          background: rgba(0, 227, 171, 0.3);
          border-radius: 50px;
          max-width: 325px;
          padding: 6px 12px;

          p {
            margin: 0;
          }
        }
      }
    }

    @media screen and (max-width: 1024px) {
      .capacity,
      .subcol {
        display: none;
      }

      .group-devices {
        width: 50%;
      }

      .group-limit-override {
        width: 40%;
      }
    }

    @media screen and (max-width: 768px) {
      flex-direction: column !important;
      
      h4 {
        margin: 0 0 6px;
      }
      hr {
        display: none;
      }
      .group-devices {
        border: none;
        padding-right: 0;
        margin-bottom: 24px;
        width: 100%;
      }

      .group-limit-override {
        width: 100%;
      }
    }
  }
`

const mapStateToProps = (state) => {
  return {
    currentCampusId: state.currentCampusId,
    currentCampusData: state.currentCampusData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentCampus: (campusId) => {
        dispatch(setCurrentCampus(campusId))
    }
  }
}
  
  export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(EVContent))