import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import getResponse from "../utilities/FetchHelper";

import AuthenticatedComponent from "../utilities/AuthenticatedComponent";
import WithRouter from "../utilities/WithRouter";
import configuration from "../config";
import {updateCurrentCampusData} from "../actions";
import icon_plus from  "../assets/icon_plus.svg";
import CreateEventModal from "../components/modals/CreateEventModal";
import ProcessingAndError from "../components/ProcessingAndError";
import EditEventModal from "../components/modals/EditEventModal";

const Events = ({
  currentCampusId,
  processing,
  setProcessing,
  errorMessage,
  setErrorMessage
}) => {
  const authenticatedComponent = new AuthenticatedComponent
  const [selectedModal, setSelectedModal] = useState(null)
  const [eventList, setEventList] = useState([])
  const [currentEventId, setCurrentEventId] = useState([])
  const [selectedEventData, setSelectedEventData] = useState()
  const errorMessageRef = useRef(null)

  useEffect(() => {
    getEvents()
  }, [])

  useEffect(() => {
    getEvents()
  }, [currentCampusId])

  const eventsCallback = (data) => {
    setEventList(data)
  }

  function getEvents() {
    authenticatedComponent.getEventsList(currentCampusId, eventsCallback)
  }

  const handleDelete = (eventId) => {
    setProcessing(true)

    getResponse(configuration["backend_host"] + "/ahiapi/"
    + currentCampusId + "/event/" + eventId,
    true,
    "DELETE", 
    null,
    getEvents,
    setProcessing,
    errorMessage,
    setErrorMessage,
    errorMessageRef
    )
  }

  const clearModal = () => {
    setSelectedModal(null)
    setSelectedEventData(null)
  }

  const openModal = (selectModal, eventId) => {
    setSelectedModal(selectModal)
    setCurrentEventId(eventId)

    if (selectModal === 'edit-event') {
      authenticatedComponent.getEventsList(currentCampusId, eventsCallback)

      if (eventList) {
        Object.entries(eventList).forEach(([key, value]) => {
          value.map((event) => {
            if (event.overrideId === eventId) {
              setSelectedEventData(event)
            }
          })
        })
      }
    }
  }

  const renderModal = () => {
    switch(selectedModal) {
      case 'create-event':
        return (
          <CreateEventModal
            modalTitle="Create an Event"
            pageName="Events"
            clearModal={clearModal}
            currentCampusId={currentCampusId}
            eventList={eventList}
            setProcessing={setProcessing}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            errorMessageRef={errorMessageRef}
            getEvents={getEvents}
          />
        )
      case 'edit-event':
        return (
          <EditEventModal
            modalTitle="Event Editor"
            pageName="Events"
            clearModal={clearModal}
            currentCampusId={currentCampusId}
            eventList={eventList}
            selectedEventData={selectedEventData}
            setProcessing={setProcessing}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            errorMessageRef={errorMessageRef}
            getEvents={getEvents}
          />
        )  
      default:
        return false
    }
  }

  let newEventList = []
  let newEventObj

  if (eventList && eventList.events) {
    
    eventList.events.map((event) => {
      let eventDate =  event.fromTime.substr(0, event.fromTime.indexOf("T"))
      let mode
      let modeClass
  
      if (event.instruction.blockCooling === false 
        && event.instruction.blockHeating === false
      ) {
        mode = 'Auto'
        modeClass = 'auto'
      } else if (event.instruction.blockCooling === true 
        && event.instruction.blockHeating === true
      ) {
        mode = 'Fan Only'
        modeClass = 'fan'
      } else if (event.instruction.blockCooling === true 
        && event.instruction.blockHeating === false
      ) {
        mode = 'Heat Only'
        modeClass = 'heat'
      } else if (event.instruction.blockCooling === false 
        && event.instruction.blockHeating === true
      ) {
        mode = 'Cool Only'
        modeClass = 'cool'
      }
  
      newEventObj = {
        overrideId: event.overrideId,
        overrideName: event.overrideName,
        mode: mode,
        modeClass: modeClass,
        lowerBound: event.instruction.lowerBound,
        upperBound: event.instruction.upperBound,
        date: eventDate,
        fromTime: event.fromTime.substr(11, 5),
        toTime: event.toTime.substr(11, 5)
      }

      newEventList = [...newEventList, newEventObj]
    })
  }
  
  const modal = renderModal()
  return (<>
    <ProcessingAndError
      processing={processing}
      errorMessage={errorMessage}
      errorMessageRef={errorMessageRef}
    />

    <section className={processing ? "events processing" : "events"}>
      <div className="events-container">
        <div className="event-list-container main-containers">
          <div className="event-header">
            <div className="title-add-button">
              <h3>Events List</h3>
              <div className="add-button" onClick={() => openModal('create-event', null)}>
                <img src={icon_plus} alt="button to add a new event" height="18" width="18" />
              </div>
            </div>
            
            <hr />
          </div>

          <div className="event-list-table events-table">
            <table>
                <thead>
                    <tr>
                        <th>Event Name</th>
                        <th>Date</th>
                        <th>Start - End</th>
                        <th>Temperature Range</th>
                        <th>Mode</th>
                        <th></th>
                    </tr>
                </thead>
                
                <tbody>
                {newEventList && newEventList.map((event) => (
                    <tr key={event.overrideName}>
                        <td>{event.overrideName}</td>
                        <td>
                          {event.date}
                        </td>
                        <td>
                          {`${event.fromTime} - ${event.toTime}`}
                        </td>
                        <td>
                          {`${event.lowerBound} - ${event.upperBound}`}
                        </td>
                        <td className='mode-container'>
                          <div className={`${event.modeClass} mode`}>
                            {event.mode}
                          </div>
                        </td>
                        <td className='edit-delete-container'>
                          <div 
                            className="delete"
                            onClick={() => handleDelete(event.overrideId)}
                          >
                            Delete
                          </div>

                          <div 
                            className="edit"
                            onClick={() => openModal('edit-event', event.overrideId)}
                          >
                          View/Edit
                          </div>
                        </td>
                    </tr>
                  ))}
                </tbody>
            </table>
          </div>
        </div>

        {modal}
      </div>
    </section>
  </>)
}

const mapStateToProps = (state) => {
  return {
    currentCampusId: state.currentCampusId,
    currentCampusData: state.currentCampusData
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateCurrentCampusData: (updatedCampusData) => {
      dispatch(updateCurrentCampusData(updatedCampusData))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(Events))