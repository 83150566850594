const SAVE_CAMPUSES = "SAVE_CAMPUSES"
const SET_CURRENT_CAMPUS = "SET_CURRENT_CAMPUS"
const SET_CURRENT_BUILDING = "SET_CURRENT_BUILDING"
const SET_CURRENT_ZONE = "SET_CURRENT_ZONE"
const SET_CURRENT_GX = "SET_CURRENT_GX"
const SET_CURRENT_GXDATA = "SET_CURRENT_GXDATA"
const SET_CURRENT_EV_DATA = "SET_CURRENT_EV_DATA"
const SET_CURRENT_BATTERY_DATA = "SET_CURRENT_BATTERY_DATA"
const SET_CURRENT_ZONE_DATA = "SET_CURRENT_ZONE_DATA"
const SET_USER_DATA = "SET_USER_DATA"
const UPDATE_CURRENT_CAMPUSDATA = "UPDATE_CURRENT_CAMPUSDATA"

const saveCampuses = (data) => {
  return {
    type: SAVE_CAMPUSES,
    data
  }
}

const setCurrentCampus = (campusId) => {
  return {
    type: SET_CURRENT_CAMPUS,
    campusId
  }
}

const setCurrentBuilding = (buildingId) => {
  return {
    type: SET_CURRENT_BUILDING,
    buildingId
  }
}

const setCurrentZone = (zoneId) => {
  return {
    type: SET_CURRENT_ZONE,
    zoneId
  }
}

const setCurrentZoneData = (zoneData) => {
  return {
    type: SET_CURRENT_ZONE_DATA,
    zoneData
  }
}

const setCurrentGX = (gxId) => {
  return {
    type: SET_CURRENT_GX,
    gxId
  }
}

const setCurrentGXData = (gxData) => {
  return {
    type: SET_CURRENT_GXDATA,
    gxData
  }
}

const setCurrentEVData = (evData) => {
  return {
    type: SET_CURRENT_EV_DATA,
    evData
  }
}

const setCurrentBatteryData = (batteryData) => {
  return {
    type: SET_CURRENT_BATTERY_DATA,
    batteryData
  }
}

const updateCurrentCampusData = (campusData) => {
  return {
    type: UPDATE_CURRENT_CAMPUSDATA,
    campusData
  }
}

const setUserData = (userData) => {
  return {
    type: SET_USER_DATA,
    userData
  }
}

export {
  saveCampuses,
  setCurrentCampus,
  setCurrentBuilding,
  setCurrentZone,
  setCurrentGX,
  setCurrentGXData,
  setCurrentEVData,
  setCurrentBatteryData,
  setCurrentZoneData,
  updateCurrentCampusData,
  setUserData,
}