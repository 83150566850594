import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import configuration from '../../config'
import getResponse from '../../utilities/FetchHelper'

import Modal from '../Modal'

const EditAllocations = ({
    clearModal,
    modalImage,
    backupResSettings,
    selectedGXId,
    setProcessing,
    errorMessage,
    setErrorMessage,
    errorMessageRef,
    updateBatteryData,
    showMobile
}) => {
    const [backupResForm, setBackupResForm] = useState({})

    useEffect(() => {
        if(selectedGXId) {
            setBackupResForm(backupResSettings)
        }
    }, [selectedGXId])

    const handleFormChange = (e) => {
        setBackupResForm(prevState => ({
            ...prevState,
            [e.target.name]: parseInt(e.target.value)
        })
        )
    }

    const updateAllocations = () => {
        setProcessing(true)
        clearModal()

        getResponse(configuration["backend_host"] + "/ahiapi/"
        + selectedGXId + "/backupReservation",
        true,
        "POST", 
        JSON.stringify(backupResForm),
        updateBatteryData,
        setProcessing,
        errorMessage,
        setErrorMessage,
        errorMessageRef
        )
    }

    return (
        <EditAllocationsDiv>
            <Modal
                modalTitle={"Economic Allocation"}
                modalImage={modalImage}
                clearModal={clearModal}
                showMobile={showMobile}
                updateSettings={updateAllocations}
                renderModalContent={(
                    <div className="allocation-container">
                        <div className="subtitles">
                            {!showMobile && (<h5>Risk of Outage</h5>)}
                            
                            <h5>Reserved for Backup Power</h5>
                        </div>
                        
                        <div className="allocation-settings">
                            <div className="backup-none risk">
                                <label htmlFor="none">None</label>
                                <div>
                                    <input 
                                        name="none"
                                        type="number" 
                                        placeholder={backupResSettings.none}
                                        defaultValue={backupResSettings.none}
                                        onChange={(e) => handleFormChange(e)} 
                                        min="1" 
                                        max="100"
                                    ></input>
                                    <span>%</span>
                                </div>
                            </div>

                            <div className="backup-low risk">
                                <label htmlFor="low">Low</label>
                                <div>
                                    <input 
                                        name="low"
                                        type="number" 
                                        placeholder={backupResSettings.low}
                                        defaultValue={backupResSettings.low}
                                        onChange={(e) => handleFormChange(e)} 
                                        min="1" 
                                        max="100"
                                    ></input>
                                    <span>%</span>
                                </div>
                            </div>

                            <div className="backup-med risk">
                                <label htmlFor="medium">Medium</label>
                                <div>
                                    <input 
                                        name="medium"
                                        type="number" 
                                        placeholder={backupResSettings.medium}
                                        defaultValue={backupResSettings.medium}
                                        onChange={(e) => handleFormChange(e)} 
                                        min="1" 
                                        max="100"
                                    ></input>
                                    <span>%</span>
                                </div>
                            </div>

                            <div className="backup-high risk">
                                <label htmlFor="high">High</label>
                                <div>
                                    <input 
                                        name="high"
                                        type="number" 
                                        placeholder={backupResSettings.high}
                                        defaultValue={backupResSettings.high}
                                        onChange={(e) => handleFormChange(e)} 
                                        min="1" 
                                        max="100"
                                    ></input>
                                    <span>%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            >
            </Modal>
        </EditAllocationsDiv>
  )
}

const EditAllocationsDiv = styled.div`
    .modal.desktop {
        max-width: 600px;
    }

    .allocation-container {
        align-items: center;
        display: flex;
        justify-content: space-between;

        .subtitles {
            display: flex;
            justify-content: space-between;
            flex-flow: column;
            height: 40px;
            
            h5 {
                font-weight: 500;
                font-style: italic;
                margin: 0;
            }
        }

        .allocation-settings {
            align-items: flex-start;
            display: flex;
            justify-content: space-between;
            width: 55%;

            input {
                background: #EDF1F2;
                border: none;
                border-radius: 8px;
                padding: 6px;
                width: 30px;
            }

            label {
                font-size: 14px;
                font-weight: 600;
            }

            .risk {
                display: flex;
                flex-flow: column;
                height: 50px;
                justify-content: space-between;
                margin: 0;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .allocation-container {
            flex-flow: wrap;
            .allocation-settings {
                width: 100%;
                .risk {
                    font-size: 10px;
                    width: 100%;
                }
            }
        }
        
    }
`

export default EditAllocations