import React, { useState, useEffect, useRef, Fragment } from "react";
import { connect } from "react-redux";
import getResponse from "../utilities/FetchHelper";

import ProcessingAndError from "../components/ProcessingAndError";
import CreateScheduleModal from "../components/modals/CreateScheduleModal";
import EditScheduleModal from "../components/modals/EditScheduleModal";
import ConfirmModal from "../components/modals/ConfirmModal";

import AuthenticatedComponent from "../utilities/AuthenticatedComponent";
import WithRouter from "../utilities/WithRouter";
import configuration from "../config";
import { updateCurrentCampusData } from "../actions";
import icon_plus from  "../assets/icon_plus.svg";

const Schedules = ({
  currentCampusId,
  openScheduleModal,
  selectedModal,
  setSelectedModal,
  currentScheduleId,
  setCurrentScheduleId,
  currentScheduleData,
  allScheduleCallback,
  showMobile,
  processing,
  setProcessing,
  errorMessage,
  setErrorMessage
}) => {
  const [selectedScheduleData, setSelectedScheduleData] = useState(null)
  const [scheduleAssignments, setScheduleAssignments] = useState([])
  const [duplicateForm, setDuplicateForm] = useState({})
  const [scheduleIdDelete, setScheduleIdDelete] = useState(null)
  const errorMessageRef = useRef(null)

  const authenticatedComponent = new AuthenticatedComponent

  useEffect(() => {
    updateAllSchedules()
    updateScheduleAssignments()

    if (sessionStorage.scheduleId) {
      openScheduleModal('edit-schedule', currentScheduleId)
      selectedSchedule()
    }
  }, [])

  useEffect(() => {
    updateAllSchedules()
    updateScheduleAssignments()
  }, [currentCampusId])

  const assignmentCallback = (data) => {
    setScheduleAssignments(data)
  }
  
  const selectedCallback = (data) => {
    setSelectedScheduleData(data)
  }

  function updateAllSchedules() {
    authenticatedComponent.getAllScheduleData(currentCampusId, 'large', allScheduleCallback)
  }

  function updateScheduleAssignments() {
    authenticatedComponent.getScheduleAssignments(currentCampusId, assignmentCallback)
  }

  function selectedSchedule() {
    authenticatedComponent.getSelectedScheduleData(currentCampusId, currentScheduleId, selectedCallback)
  }

  const handleAssignment = (e, zoneKey) => {
    clearModal();
    setProcessing(true);

    let newScheduleName = encodeURIComponent(e.target.value)

    getResponse(configuration["backend_host"] + "/ahiapi/"
    + zoneKey + "/thermalZone/configuration",
    false,
    "POST", 
    `scheduleName=${newScheduleName}`,
    updateScheduleAssignments,
    setProcessing,
    errorMessage,
    setErrorMessage,
    errorMessageRef
    )
  }

  const confirmDelete = (scheduleId) => {
    setScheduleIdDelete(scheduleId)
    openScheduleModal('confirm')
  }

  const handleDelete = () => {
    clearModal();
    setProcessing(true);

    getResponse(configuration["backend_host"] + "/ahiapi/"
    + currentCampusId + "/schedule/" + scheduleIdDelete,
    true,
    "DELETE", 
    null,
    updateAllSchedules,
    setProcessing,
    errorMessage,
    setErrorMessage,
    errorMessageRef
    )

    setScheduleIdDelete(null)
  }

  const clearModal = () => {
    setSelectedModal(null)
    setSelectedScheduleData(null)
    setCurrentScheduleId(null)

    sessionStorage.removeItem('scheduleId')
  }

  const handleEditModal = (scheduleId) => {
    setCurrentScheduleId(scheduleId)
    openScheduleModal('edit-schedule', scheduleId)

    authenticatedComponent.getSelectedScheduleData(currentCampusId, scheduleId, selectedCallback)
  }

  const handleDuplicate = (scheduleId) => {
    //get selected schedule
    fetch(configuration['backend_host'] + '/ahiapi/'
    + currentCampusId
    + '/schedule/'
    + scheduleId, 
    {
      headers: authenticatedComponent.generateAuthenticatedHeader(true),
    })

    .then(response => {
      if (!response.ok) {
        throw new Error('Error duplicating the schedule.')
      }
      
      return response.json()
    })

    .then(data => {
      let newSchedule = data

      newSchedule = {...newSchedule, 
        scheduleName: `${newSchedule.scheduleName} - Duplicate`,
        scheduleId: null
      }
      setDuplicateForm(newSchedule)
    })

    .catch(error => console.error(error))
  }

  useEffect(() => {
    if (Object.keys(duplicateForm).length !== 0) {
      setProcessing(true)

      getResponse(configuration["backend_host"] + "/ahiapi/"
      + currentCampusId + "/schedule",
      true,
      "POST", 
      JSON.stringify(duplicateForm),
      updateAllSchedules,
      setProcessing,
      errorMessage,
      setErrorMessage,
      errorMessageRef
      )

      .then(() => {
        setDuplicateForm({})
        updateAllSchedules()
      })
    }
    
  }, [duplicateForm])

  const renderModal = () => {
    switch(selectedModal) {
      case 'create-schedule':
        return (
          <CreateScheduleModal
            modalTitle="Create a Schedule"
            pageName="Schedules"
            clearModal={clearModal}
            currentCampusId={currentCampusId}
            setProcessing={setProcessing}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            errorMessageRef={errorMessageRef}
            updateAllSchedules={updateAllSchedules}
          />
        )
      case 'edit-schedule':
        return (
          <EditScheduleModal
            modalTitle="Schedule Editor"
            pageName="Schedules"
            clearModal={clearModal}
            currentCampusId={currentCampusId}
            selectedScheduleData={selectedScheduleData}
            setProcessing={setProcessing}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            errorMessageRef={errorMessageRef}
            updateAllSchedules={updateAllSchedules}
            showMobile={showMobile}
          />
        )
      case 'confirm':
        return <ConfirmModal 
          modalMessage={'Are you sure you want to delete this schedule?'}
          modalAction={() => handleDelete()}
          clearModal={clearModal}
          />
      default:
        return false
    }
  }

  const editDeleteContainer = (schedule) => (
    <td className='edit-delete-container'>
      <div 
        className="delete"
        onClick={() => confirmDelete(schedule.scheduleId)}
      >
        Delete
      </div>

      <div 
        className="duplicate"
        onClick={() => handleDuplicate(schedule.scheduleId)}
      >
      Duplicate
      </div>

      <div 
        className="edit"
        onClick={() => handleEditModal(schedule.scheduleId)}
      >
      View/Edit
      </div>
    </td>
  )
  
  const modal = renderModal()
  return (<>
    <ProcessingAndError
      processing={processing}
      errorMessage={errorMessage}
      errorMessageRef={errorMessageRef}
    />

    <section className="schedules">
      <div className="schedules-container">
        <div className="schedule-list-container main-containers">
          <div className="schedule-header">
            <div className="title-add-button">
              <h3>Schedules List</h3>
              <div className="add-button" onClick={() => openScheduleModal("create-schedule", null)}>
                <img src={icon_plus} alt="button to add a new schedule" height={18} width={18} />
              </div>
            </div>
            
            <hr />
          </div>
          
          <div className="schedule-list-table schedules-table">
            <table>
              <thead>
                  <tr>
                    {showMobile ? (<>
                      <th>Schedule Name</th>
                      <th></th>
                    </>) 
                    : 
                    (<>
                      <th>Schedule Name</th>
                      <th>Days</th>
                      <th>Start - End</th>
                      <th>Temperature Range</th>
                      <th>Mode</th>
                      <th></th>
                    </>)}
                  </tr>
              </thead>
              
              <tbody>
                {showMobile ? (<>
                  {currentScheduleData && currentScheduleData.map((schedule) => (
                    <tr className="mobile" key={`${schedule.scheduleId}-${schedule.scheduleName}`}>
                      <td>{schedule.scheduleName}</td>
                      {editDeleteContainer(schedule)}
                    </tr>
                  ))}
                </>) 
                :
                (<>
                  {currentScheduleData && currentScheduleData.map((schedule) => (
                    schedule.details.length <= 1 ? (
                      schedule.details.map((detail) => (
                        <tr className="title-row single-entry" key={`${schedule.scheduleId}-${schedule.scheduleName}`}>
                          <td>{schedule.scheduleName}</td>
                        
                          <td>{detail.days}</td>
                          <td>{detail.times}</td>
                          <td>{detail.temps}</td>
                          <td className="detail-mode">
                            <div className={detail.mode.toLowerCase().replace(/[\s;]+/g, "-")}>
                              {detail.mode}
                            </div>
                          </td>
                          {editDeleteContainer(schedule)}
                        </tr>
                      )) 
                    )
                    : 
                    (<Fragment key={`${schedule.scheduleId}-${schedule.scheduleName}`}>
                      <tr className="title-row multi-entry">
                        <td colSpan={5}>{schedule.scheduleName}</td>
                        {editDeleteContainer(schedule)}
                      </tr>
                      {schedule.details.map((detail, index) => (
                        <tr 
                          className={detail.exception === true ? "cal-adjustment multi-entry" : "multi-entry"}
                          key={`schedule-item-${index}`}
                        >
                          <td></td>
                          <td>{detail.exception === true ? 
                            (<>
                              <div>
                                {detail.days}
                              </div>
                              <div>
                                {detail.dates}
                              </div>
                            </>)
                            : 
                            (detail.days)
                          }
                          </td>
                          <td>{detail.times}</td>
                          <td>{detail.temps}</td>
                          <td className="detail-mode">
                            <div className={detail.mode.toLowerCase().replace(/[\s;]+/g, "-")}>
                              {detail.mode}
                            </div>
                          </td>
                          <td></td>
                        </tr>
                      ))}
                    </Fragment>)
                  ))}
                </>)}
                
              </tbody>
            </table>
          </div>
        </div>

        <div className="schedule-assignment-container main-containers">
          <div className="schedule-header">
            <div className="title-add-button">
              <h3>Schedule Assignment</h3>
            </div>
            
            <hr />
          </div>

          <div className="schedule-assignment-table schedules-table"
          >
            {scheduleAssignments && scheduleAssignments.map((building) => (
              <table key={building.buildingShortDescription}>
                <thead>
                    <tr>
                        <th colSpan={2}>{building.buildingLongDescription}</th>
                    </tr>
                </thead>
                
                <tbody>
                  {building.thermalZones.map((zone) => (
                    <tr className='zone-entry' key={zone.thermalZoneEntityKey}>
                        <td>{zone.thermalZoneLongDescription}</td>
                        <td>
                          <select
                            name=""
                            id=""
                            value={zone.scheduleName ? 
                              zone.scheduleName
                              :
                              ""
                            }
                            onChange={(e) => handleAssignment(e, zone.thermalZoneEntityKey)}
                          >
                            {currentScheduleData && currentScheduleData.map((schedule, index) => (
                              <option 
                                value={schedule.scheduleName}
                                key={`${schedule.scheduleId}-${index}`}
                              >
                                {schedule.scheduleName}
                              </option>
                            ))}

                            {zone.scheduleName === null && (<>
                              <option value="" disabled>
                                No Schedule
                              </option> 
                            </>)}
                          </select>
                        </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ))}
          </div>
        </div>

        {modal}
      </div>
    </section>
  </>)
}

const mapStateToProps = (state) => {
  return {
    currentCampusId: state.currentCampusId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateCurrentCampusData: (updatedCampusData) => {
      dispatch(updateCurrentCampusData(updatedCampusData))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(Schedules))