import React, { useState } from 'react';
import {connect} from 'react-redux';
import { NavLink } from 'react-router-dom'
import styled from '@emotion/styled';

import WithRouter from '../utilities/WithRouter';
import { setCurrentCampus } from '../actions';
import Schedules from './Schedules';
import Events from './Events';

import icon_schedule from '../assets/icon_schedule.svg';

const Scheduler = ({
    currentCampusData,
    currentCampusId,
    openScheduleModal,
    selectedModal,
    setSelectedModal,
    currentScheduleId,
    setCurrentScheduleId,
    allScheduleCallback,
    currentScheduleData,
    showMobile,
    processing,
    setProcessing,
    errorMessage,
    setErrorMessage,
    router
}) => {
    const [schedulerMenuActive, setSchedulerMenuActive] = useState(window.location.pathname.split('/')[3].toLowerCase())

    const handleMenuClick = (type) => {
        setSchedulerMenuActive('')
        setSchedulerMenuActive(type)

        router.navigate(`/scheduler/${currentCampusId}/${type}`)
    }

    return (
        <SchedulerSection className={showMobile ? 'mobile' : 'desktop'}>
            <div className="header-container">
                <div className="title-menu">
                    <div className="header">
                        <img src={icon_schedule} alt="network of schedulers icon" width="18" height="18" />
                        <h1>Scheduler</h1>
                    </div>

                    <nav className="scheduler-nav">
                        {showMobile ? (<>
                            <select
                                name="mobile-menu"
                                id="mobile-menu"
                                defaultValue={schedulerMenuActive}
                                onChange={(e) => handleMenuClick(e.target.value)}
                            >
                                <option value="schedules">
                                    Schedules
                                </option>
                                <option value="events">
                                    Events
                                </option>
                            </select>
                        </>) : (<>
                            <NavLink to={`/scheduler/${currentCampusId}/schedules`}>
                                <div 
                                    className={schedulerMenuActive === "schedules" ? "active" : ""}
                                    onClick={() => handleMenuClick('schedules')}
                                >
                                    Schedules
                                </div>
                                </NavLink>

                            <NavLink to={`/scheduler/${currentCampusId}/events`}>
                                <div 
                                className={schedulerMenuActive === "events" ? "active" : ""}
                                onClick={() => handleMenuClick('events')}
                                >
                                    Events
                                </div>
                            </NavLink>
                        </>)}
                    </nav>
                </div>
            </div>
            
            <section className="schedulers-section">
                {schedulerMenuActive === 'schedules' ? 
                (
                    <Schedules
                        openScheduleModal={openScheduleModal}
                        selectedModal={selectedModal}
                        setSelectedModal={setSelectedModal}
                        currentScheduleId={currentScheduleId}
                        setCurrentScheduleId={setCurrentScheduleId}
                        allScheduleCallback={allScheduleCallback}
                        currentScheduleData={currentScheduleData}
                        showMobile={showMobile}
                        processing={processing}
                        setProcessing={setProcessing}
                        errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage}
                    />
                )
                :
                (
                    <Events
                        currentCampusData={currentCampusData}
                        currentCampusId={currentCampusId}
                        showMobile={showMobile} 
                        processing={processing}
                        setProcessing={setProcessing}
                        errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage}
                    />
                )}
            </section>
        </SchedulerSection>
    )
}

const SchedulerSection = styled.section`
    color: #02222B;
    height: 100vh;
    overflow: auto;
    padding-right: 24px;

    .header-container,
    .title-menu {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    .title-menu {
        max-width: 350px;
    }

    .header-container {
        margin-bottom: 12px;

        .scheduler-nav {
            display: flex;
            justify-content: space-between;
            line-height: 26px;
            
            margin-left: 24px;
            padding-bottom: 14px;

            div {
                cursor: pointer;
                font-size: 16px;
                font-weight: 600;
                list-style: none;
                margin: 0px 4px;
                opacity: 0.5;
                padding-bottom: 4px;
                text-align: center;
                width: 100px;

                &.active {
                    border-bottom: 3px #00E3AB solid;
                    opacity: 1;
                }
            }
        }

        .add-group {
            padding-bottom: 24px;
            button {
                align-items: center;
                background: #C2D0D1;
                border: none;
                border-radius: 8px;
                color: #02222B;
                display: flex;
                font-size: 14px;
                font-weight: 600;
                justify-content: space-evenly;
                padding: 6px 10px;
                width: 128px; 
            }
        }
    }

    .mobile {
        .schedulers-section {
            justify-content: center;
        }
        td:nth-of-type(2) {
            width: 125px;
        }
    }

    h4 {
        font-size: 16px;
        margin: 0;
    }

    hr {
        background: #C2D0D1;
        border: none;
        height: 2px;
        margin: 12px 0 24px;
    }
`

const mapStateToProps = (state) => {
  return {
    currentCampusId: state.currentCampusId,
    currentCampusData: state.currentCampusData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentCampus: (campusId) => {
        dispatch(setCurrentCampus(campusId))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(Scheduler))