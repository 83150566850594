import authentication from "./Authentication"
import AuthenticatedComponent from "./AuthenticatedComponent"

const authenticatedComponent = new AuthenticatedComponent

function getResponse(url, authHeader, method, body, callback, setProcessing, errorMessage, setErrorMessage, errorMessageRef) {
  let formattedHeaders

  const generateAuthenticatedHeader = (addPostHeader) => {
    const headerObj = authentication.generateHeaderObject()
    const authenticatedHeaders = new Headers(headerObj)
    if (addPostHeader) {
      authenticatedHeaders.set("Content-Type", "application/x-www-form-urlencoded")
    }
    return authenticatedHeaders
  }

  if (method === "POST" || method === "DELETE") {
    formattedHeaders = {
      headers: authHeader ? authenticatedComponent.generateAuthenticatedHeader(true) : generateAuthenticatedHeader(true),
      method: method, 
      body: body
    }
  } else {
    formattedHeaders = {
      headers: authenticatedComponent.generateAuthenticatedHeader(),
    }
  }

  fetch(url, formattedHeaders)

  .then((response) => {
    if (!response.ok) {
      setProcessing(false);

      setErrorMessage("Something went wrong. Try again or contact customer support.");

      setTimeout(() => {
          setErrorMessage(null);
      }, 9000)

      throw new Error(`Fetch failed. Error: ${response.status} ${response.statusText}`);
    }
  })

  .then((data) => {
    callback()
  
    setTimeout(() => {
      setProcessing(false);
    }, 8000)
  })

  .catch((error) => {
    setProcessing(false);

    setTimeout(() => {
      if (errorMessage === null && errorMessageRef.current === null) {
        setErrorMessage("Something went wrong. Try again or contact customer support.");

        setTimeout(() => {
          setErrorMessage(null)
        }, 9000)
      }
    }, 1000)

    console.error(error)
  })
}

export default getResponse