import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import moment from "moment";
import configuration from "../../config";
import getResponse from "../../utilities/FetchHelper";

import Modal from "../Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ScheduleEventName from "./ScheduleEventName";
import StartEndTime from "./StartEndTime";
import LowerUpperMode from "./LowerUpperMode";
import ZoneSelector from "./ZoneSelector";

import icon_event from "../../assets/icon_event.svg";
import AuthenticatedComponent from "../../utilities/AuthenticatedComponent";

const CreateEventModal = ({
    modalTitle,
    clearModal,
    showMobile,
    pageName,
    currentCampusId,
    eventList,
    getEvents,
    setProcessing,
    errorMessage,
    setErrorMessage,
    errorMessageRef
}) => {
    const authenticatedComponent = new AuthenticatedComponent
    const newDate = new Date()
    let year = newDate.toLocaleString("default", { year: "numeric" });
    let month = newDate.toLocaleString("default", { month: "2-digit" });
    let day = newDate.toLocaleString("default", { day: "2-digit" });
    let formattedDate = year + "-" + month + "-" + day;

    const [eventName, setEventName] = useState(`New Event: ${formattedDate}`)
    const [startDate, setStartDate] = useState(newDate)
    const [eventForm, setEventForm] = useState({
        campusEntityKey: currentCampusId,
        eventName: eventName,
        blockCooling: false,
        blockHeating: false,
        lowerBound: 68,
        upperBound: 75,
        startTime: '06:00',
        endTime: '14:30',
        date: formattedDate,
    })
    const [newLowerBound, setNewLowerBound] = useState(eventForm.lowerBound)
    const [newUpperBound, setNewUpperBound] = useState(eventForm.upperBound)
    const [newBlockCooling, setNewBlockCooling] = useState(eventForm.blockCooling)
    const [newBlockHeating, setNewBlockHeating] = useState(eventForm.blockHeating)
    const [newEventDate, setNewEventDate] = useState(formattedDate)
    const [newFromTime, setNewFromTime] = useState('06:00')
    const [newToTime, setNewToTime] = useState('14:30')
    const [thermalZoneList, setThermalZoneList] = useState([])
    const [zoneList, setZoneList] = useState([])
    const hasZoneSelected = thermalZoneList.length === 0

    useEffect(() => {
        authenticatedComponent.getEventAssignmentList(currentCampusId, eventsAssignmentCallback)
    }, [])

    const eventsAssignmentCallback = (data) => {
        Object.entries(data).forEach(([key, value]) => {
            setZoneList(value)
        })
    }

    const handleName = (name) => {
        setEventName(name)

        setEventForm(prevState => ({...prevState, 
            eventName: name,
        }))
    }

    const handleChange = (index = null, name, value) => {
        let newZoneList

        // lowerbound upperbound mode
        if (name === 'lowerBound') {
            setNewLowerBound(value)

            setEventForm(prevState => ({...prevState, 
                lowerBound: value,
            }))
        } else if (name === 'upperBound') {
            setNewUpperBound(value)

            setEventForm(prevState => ({...prevState, 
                upperBound: value,
            }))
        } else if (name === 'heat') {
            setNewBlockCooling(true)
            setNewBlockHeating(false)

            setEventForm(prevState => ({...prevState, 
                blockCooling: true,
                blockHeating: false,
            }))
        } else if (name === 'cool') {
            setNewBlockCooling(false)
            setNewBlockHeating(true)

            setEventForm(prevState => ({...prevState, 
                blockCooling: false,
                blockHeating: true,
            }))
        } else if (name === 'fan') {
            setNewBlockCooling(true)
            setNewBlockHeating(true)

            setEventForm(prevState => ({...prevState, 
                blockCooling: true,
                blockHeating: true,
            }))
        } else if (name === 'auto') { 
            setNewBlockCooling(false)
            setNewBlockHeating(false)

            setEventForm(prevState => ({...prevState, 
                blockCooling: false,
                blockHeating: false,
            }))
        }

        //start end time
        //format: "2023-12-09T12:27:00"
        if (name === 'startTime') {
            setNewFromTime(value)

            setEventForm(prevState => ({...prevState, 
                startTime: value,
            }))
        } else if (name === 'endTime') {
            setNewToTime(value)

            setEventForm(prevState => ({...prevState, 
                endTime: value,
            }))
        }

        //date
        if (name === 'date') {
            let newDay = value.getDate() < 10 ? `0${value.getDate()}` : value.getDate()
            let indexMonth = value.getMonth() + 1
            let newMonth = indexMonth < 10 ? `0${indexMonth}` : indexMonth

            setNewEventDate(value.getFullYear()
            + '-' +
            newMonth
            + '-' +
            newDay)

            setStartDate(value)

            setEventForm(prevState => ({...prevState, 
                date: value.getFullYear()
                + '-' +
                newMonth
                + '-' +
                newDay
            }))
        }

        //selected zones
        if (name === 'selectedZones') {
            newZoneList = value.map((zone) => `thermalZoneEntityKey=${zone}`).join('&')
            setThermalZoneList(newZoneList)
        }
    }

  const saveCreateEvent = () => {
    setProcessing(true)

    const encodeGetParams = p => 
    Object.entries(p).map(item => item.map(encodeURIComponent).join("=")).join("&")

    let newParams = encodeGetParams(eventForm)
    let newEventForm = newParams + '&' + thermalZoneList

    getResponse(configuration["backend_host"] + "/ahiapi/"
    + currentCampusId + "/event",
    false,
    "POST", 
    newEventForm,
    getEvents,
    setProcessing,
    errorMessage,
    setErrorMessage,
    errorMessageRef
    )
  }

  return (
    <Modal
      modalTitle={modalTitle}
      modalImage={icon_event}
      clearModal={clearModal}
      updateSettings={saveCreateEvent}
      showMobile={showMobile}
      shouldDisable={hasZoneSelected}
      renderModalContent={(<>
        <EventNameDate className="event-name-date-container">
            <ScheduleEventName 
                className="name-container"
                pageName={pageName}
                handleName={handleName}
                scheduleName={eventName}
            />

            <div className="date-container">
                <label>
                    Date
                    <div className="date-input-container">
                        <DatePicker 
                            selected={startDate}
                            onChange={(date) => handleChange(null, 'date', date)}
                        />
                    </div>
                </label>
            </div>
        </EventNameDate>
        

        <EventSettings className="event-settings-container">
            <StartEndTime
                handleChange={handleChange}
            />
            <CreateEvent className="create-event-container">
                <div className="create-event-settings">
                    <ZoneSelector
                        zoneList={zoneList}
                        eventList={eventList}
                        handleChange={handleChange}
                    />
            
                    <div className="end-container">
                        <LowerUpperMode 
                            handleChange={handleChange}
                        />
                    </div>
                </div>
            </CreateEvent>
        </EventSettings>
      </>)}
    /> 
  )
}

const CreateEvent = styled.div`
    .create-event-settings {
        display: flex;
        justify-content: space-between;
        padding: 24px 0;
        width: 100%;

        & > div {
            width: 48%
        }

        .end-container {
            align-items: flex-end;
            display: flex;
            flex-flow: column;
            justify-content: space-between;

            .remove {
                cursor: pointer;
                font-size: 12px;
                text-decoration: underline;
            }
        }
    }
`

const EventNameDate = styled.div`
    align-items: center;  
    display: flex;
    justify-content: space-between;

    .date-container,
    .schedule-name-container {
        padding-bottom: 18px;
        width: 48% !important;
    }

    .date-container {
        label {
            font-weight: 500;
        }
        .date-input-container {
            background: #F2F4F7;
            border-radius: 8px;
            margin-top: 10px;
            input {
                border: none;
                cursor: pointer;
                padding: 12px 10px 12px 44px;
            }
        }
    }
`

const EventSettings = styled.div`
    max-height: 428px;

    .time-container {
        display: flex;
        flex-flow: row;
    }

    .time-container > div {
        width: 48%
    }
`

export default CreateEventModal