import Loading from "./Loading";
import Message from "./Message";

const ProcessingAndError = ({ processing, errorMessage, errorMessageRef}) => {
    return (<>
        {processing && (<Loading message="Processing request"/>)}

        {errorMessage && (
            <div className="error-message" ref={errorMessageRef}>
                <Message displayMessage={errorMessage} type="error" />
            </div>
        )}
    </>)
}

export default ProcessingAndError