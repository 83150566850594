import React from 'react'
import styled from '@emotion/styled'

const ConfirmModal = ({
    clearModal,
    modalMessage,
    modalAction,
}) => (
    <ConfirmModalDiv>
        <div className="confirm-modal">
            <span>{modalMessage}</span>
            <div className="modal-actions">
                <button className="cancel" onClick={clearModal}>Cancel</button>
                <button
                    className="confirm"
                    onClick={modalAction}
                >
                    Confirm
                </button>
            </div>
        </div>

        <div className="background-modal"></div>
    </ConfirmModalDiv>
)

const ConfirmModalDiv = styled.div`
    align-items: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-top: 20px;

    body {
        overflow: hidden;
    }

    .confirm-modal {
        align-items: center;
        display: flex;
        flex-flow: column;
        height: 85px;
        justify-content: space-between;
        background: white;
        border-radius: 8px;
        box-shadow: 0px 0px 30px 0px #02222B;
        left: calc(50vw - 110px);
        padding: 24px;
        position: fixed;
        top: calc(50vh - 30px);
        width: 220px;
        z-index: 102;

        span {
            color: #02222B;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.2;
        }

        .modal-actions {
            align-items: center;
            display: flex;
            justify-content: space-between;
            width: 100%;

            button {
                border-radius: 8px;
                cursor: pointer;
                font-weight: 600;
                height: 30px;
                transition: ease-in-out all 250ms;
                width: 100px;
                &:hover {
                    filter: brightness(0.9);
                    transition: ease-in-out all 250ms;
                }
            }

            button.confirm {
                background: #02222B;
                border: none;
                color: white;
            }

            button.cancel {
                background: white;
                border: 1px solid #D0D5DD;
                color: #02222B;
            }
        }
    }

    .background-modal {
        -webkit-backdrop-filter: blur(8px);
        background: rgba(52, 64, 84, 0.70);
        backdrop-filter: blur(8px);
        width: 100vw;
        height: 100vh;
        left: 0;
        position: absolute;
        z-index: 101;
        top: 0;
        overflow: hidden;
    }

    @media screen and (max-width: 768px) {
        .confirm-modal {
            left: calc(50vw - 120px);
        }
    }
`

export default ConfirmModal