import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import configuration from "../../config";
import getResponse from "../../utilities/FetchHelper";

import Modal from "../Modal";
import Checkbox from "../Checkbox";

const EditEvGroup = ({
    clearModal,
    modalImage,
    selectedGXId,
    selectedGroup,
    updateDeviceData,
    setProcessing,
    errorMessage,
    setErrorMessage,
    errorMessageRef,
    showMobile,
    allEVs
}) => {
    const [groupForm, setGroupForm] = useState({})
    const [selectedKeys, setSelectedKeys] = useState([])
    const [isNewGroup, setIsNewGroup] = useState(false)
    const [capacityArray, setCapacityArray] = useState([])

    useEffect(() => {
        let initialKeys = []

        calculateCapacity()
        
        if (selectedGroup && selectedGroup.evChargers) {
            selectedGroup.evChargers?.map((charger) => {
                setSelectedKeys(prevState => [...prevState, charger.entityKey])
                initialKeys.push(charger.entityKey)
            })
        } 
        
        setGroupForm({
            evChargerGroupName: selectedGroup.evChargerGroupName,
            evChargerGroupId: selectedGroup.evChargerGroupId,
            evChargerEntityKeys: initialKeys,
            powerDecisionLimits: selectedGroup.powerDecisionLimits
        })

        if (selectedGroup.evChargerGroupId === "") {
            setIsNewGroup(true)
        }
    }, [])

    useEffect(() => {
        setGroupForm(prevState => ({
            ...prevState,
            evChargerEntityKeys: selectedKeys,
        }))

        calculateCapacity()
    }, [selectedKeys])

    function calculateCapacity() {
        let valuesArray = []
        let newCapacityArray = []
        let maxTotal = 0

        Object.values(selectedGroup.powerDecisionLimits).map((value) => {
            valuesArray.push(value/100)
        })

        allEVs?.map((charger) => {
            if (selectedKeys.includes(charger.entityKey)) {
                maxTotal = maxTotal + charger.maxCapacityPowerkW
            }
        })

        for (let i = 0; i < valuesArray.length; i++) {
            newCapacityArray.push((valuesArray[i] * maxTotal).toFixed(2))
        }

        setCapacityArray(newCapacityArray)
    }

    const handleDeviceClick = (e) => {
        const {id, checked} = e.target

        if (checked) {
            setSelectedKeys(prevState => [...prevState, id]);
        } else {
            setSelectedKeys(selectedKeys.filter((item) => item !== id));
        }
    }

    const handleSelectAll = (e) => {
        const {checked} = e.target

        if (checked) {
            setSelectedKeys(allEVs.map(charger => charger.entityKey))
        } else {
            setSelectedKeys([])
        }
    }

    const groupList = allEVs?.map((charger) => {
        return (
            <div className="devices" key={charger.entityKey}>
                <Checkbox
                    id={charger.entityKey}
                    name={charger.shortDescription}
                    type="checkbox"
                    onChange={handleDeviceClick}
                    checked={selectedKeys.includes(`${charger.entityKey}`)}
                />
                {charger.shortDescription}
            </div>
        )
    })

    const handleInputUpdate = (e) => {
        const {name, value} = e.target

        Object.entries(groupForm.powerDecisionLimits).map(([key, currentValue]) => {
            if (key === name) {
                groupForm.powerDecisionLimits[name] = parseInt(value)
            }
        })

        if (name === 'group-name') {
            groupForm.evChargerGroupName = value
        }

        calculateCapacity()
    }

    const saveUpdatedGroup = () => {
        setProcessing(true)

        getResponse(configuration["backend_host"] + "/ahiapi/"
        + selectedGXId + "/gridInterconnect/evChargerGroup",
        true,
        "POST", 
        JSON.stringify(groupForm),
        updateDeviceData,
        setProcessing,
        errorMessage,
        setErrorMessage,
        errorMessageRef
        )
    }

    return (
        <EditEvGroupDiv>
            <Modal
                modalTitle={isNewGroup ? "Create New Group" : "Group Edit"}
                modalImage={modalImage}
                clearModal={clearModal}
                showMobile={showMobile}
                updateSettings={saveUpdatedGroup}
                renderModalContent={
                    (<>
                        {selectedGroup && (<>
                            <div className="group-edit">
                                <input 
                                    className="name-container"
                                    name="group-name" 
                                    placeholder={selectedGroup.evChargerGroupName}
                                    onChange={(e) => handleInputUpdate(e)} 
                                ></input>

                                <div className="settings-container">
                                    <div className="limits-container">
                                        <div className="limits">
                                            <h5>Set Limits</h5>
                                            {Object.entries(selectedGroup.powerDecisionLimits).map(([key,value]) => (
                                                <React.Fragment key={`${key}-limits`}>
                                                    <div className="limit">
                                                        <label htmlFor="performance">{key}</label>
                                                        
                                                        <div>
                                                            <input 
                                                                name={key} 
                                                                type="number" 
                                                                placeholder={value}
                                                                defaultValue={value}
                                                                onChange={(e) => handleInputUpdate(e)} 
                                                                min="1" 
                                                                max="100"
                                                            ></input>
                                                            <span>%</span>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            ))}
                                        </div>
                                    </div>
                                    
                                    <div className="capacity-container">
                                        <h5>Capacity</h5>
                                        {capacityArray.map((item, index) => (
                                            <div className="capacity" key={`${index}-capacity`}>{item} kW</div>
                                        ))}
                                    </div>

                                    <hr />

                                    <div className="devices-container">
                                        <h5>Select Device(s)</h5>

                                        <div className="devices">
                                            <Checkbox
                                                id="all"
                                                name="all"
                                                type="checkbox"
                                                onChange={handleSelectAll}
                                                checked={selectedKeys.length === groupList.length}
                                            />
                                            Select All
                                        </div>

                                        {groupList}
                                    </div>
                                </div>
                            </div>
                        </>)}
                    </>)
                }
            />
        </EditEvGroupDiv>
    )
}

const EditEvGroupDiv = styled.div`
    .modal.desktop {
        max-width: 600px;
    }

    input.name-container {
        background: #EDF1F2;
        border: none;
        border-radius: 8px;
        font-size: 16px;
        font-weight: 600;
        padding: 10px 12px;
        width: calc(100% - 24px);
    }

    .settings-container {
        align-items: flex-start;
        column-gap: 24px;
        display: flex;

        hr {
            background: #C2D0D1;
            border: none;
            height: 20vh;
            margin: 36px 0 0;
            width: 2px;
        }

        h5 {
            font-size: 14px;
            font-style: italic;
            font-weight: 500;
        }

        .limits-container {
            align-items: flex-start;
            display: flex;

            input {
                background: #EDF1F2;
                border: none;
                border-radius: 8px;
                padding: 6px;
                width: 45px;
            }
        }

        .limit {
            justify-content: space-between;
            width: 168px;
        }

        .limit,
        .capacity,
        .devices {
            align-items: center;
            display: flex;
            height: 28px;
            margin: 0 0 12px;
        }

        .capacity {
            justify-content: flex-end;
        }

        .devices {
            column-gap: 12px;
        }

        .devices-container {
            display: flex;
            flex-flow: column;
        }
    }

    @media screen and (max-width: 768px) {
        .settings-container {
            flex-flow: row wrap;

            hr {
                height: 2px;
                margin: 0 auto;
                width: 60vw;
            }
        }

        
    }
`

export default EditEvGroup