import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import StatsDetail from './StatsDetail'

const MeterCardContainer = ({ 
    gridPower,
    solarPower,
    batteryPower,
    batterySoc,
    batterySockWh,
    hasSolar,
    hasBattery,
    hasEv,
    evPower,
    hvacPower,
    otherPower,
    variant = 'primary'
}) => {
    let currentTime = new Date().toJSON().slice(0, 19)

    return(<>
        <StyledMeterCardDiv>
            <div className="meter-stats-container" >
                <StatsDetail className="meter-stats">
                    <div className="meter-label">Grid</div>
                    <div>{gridPower === null ? '---' : `${gridPower} kW`}</div>
                </StatsDetail>

                {hasSolar && (
                    <StatsDetail className="meter-stats">
                        <div className="meter-label">Solar</div>
                        <div>{solarPower === null ? '---' : `${solarPower} kW`}</div>
                    </StatsDetail>
                )}

                {hasBattery && (<>
                    <StatsDetail className="meter-stats">
                        <div className="meter-label">Battery</div>
                        <div>{batteryPower === null ? '---' : `${batteryPower} kW`}</div>
                    </StatsDetail>

                    <StatsDetail className="meter-stats">
                        <div className="meter-label">Battery SOC</div>
                        <div>{batterySoc === null ? '---' : `${batterySoc} % / ${batterySockWh} kWh`}</div>
                    </StatsDetail>
                </>)}
                
                <StatsDetail className="meter-stats">
                    <div className="meter-label">HVAC</div>
                    <div>{hvacPower !== null ? `${hvacPower} kW` : '---'}</div>
                </StatsDetail>
                
                {hasEv && (
                    <StatsDetail className="meter-stats">
                        <div className="meter-label">EV</div>
                        <div>{evPower === null ? '---' : `${evPower} kW`}</div>
                    </StatsDetail>
                )}

                {otherPower && (
                    <StatsDetail className="meter-stats">
                        <div className="meter-label">Building/Other</div>
                        <div>{otherPower !== null ? `${otherPower} kW` : '---'}</div>
                    </StatsDetail>
                )}

                {/* <div className="power-health">
                    <div className={meterOnline ? "online" : "offline"}>{meterOnline ? 'Meter Online' : 'Meter Offline'}</div>
                    {hasSolar && (<div className={solarOnline ? "online" : "offline"}>{solarOnline ? 'Solar Online' : 'Solar Offline'}</div>)}
                    {hasBattery && (<div className={batteryOnline ? "online" : "offline"}>{batteryOnline ? 'Battery Online' : 'Battery Offline'}</div>)}
                </div> */}
            </div>
        </StyledMeterCardDiv>
    </>)
}

const StyledMeterCardDiv = styled.div`
    border-right: 1px solid #C2D0D1;
    max-width: 400px;
    width: 35%;

    .meter-stats-container {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        width: 100%;

        .meter-stats {
            align-items: center;
            color: #02222B;
            display: flex;
            font-weight: 500;
            height: 30px;
            justify-content: space-between;
            min-width: 190px;
            padding-right: 30px;
            width: 100%;

            .meter-label {
                font-weight: 600;
                padding-right: 24px;
            }
        }
    } 

    @media screen and (max-width: 768px) {
        border-right: none;
        width: 100%;

        .meter-stats-container .meter-stats {
            padding-right: 0;
        }
    }
`

MeterCardContainer.propTypes = {
    meterId: PropTypes.string,
    currentPower: PropTypes.number,
    peakDemandMonth: PropTypes.number,
    kwhMonth: PropTypes.number,
    handleClick: PropTypes.func,
    variant: PropTypes.string,
}

export default MeterCardContainer