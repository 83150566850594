import React, { useState } from "react";
import Modal from "./Modal";
import configuration from "../config";
import getResponse from "../utilities/FetchHelper";
import styled from "@emotion/styled";

import icon_override from "../assets/icon_override.svg";
import icon_plus from "../assets/icon_plus.svg";
import icon_minus from "../assets/icon_minus.svg";

const ComfortModalRemoteOverride = ({
  clearModal,
  zoneData,
  showMobile,
  updateZoneData,
  setProcessing,
  errorMessage,
  setErrorMessage,
  errorMessageRef
}) => {
  const [setpoint, updateSetpoint] = useState(72)
  const [postData, setPostData] = useState({
    mode: 'heat',
    setpoint,
    durationInMinutes: 60
  })

  const decrementSetpoint = () => {
    updateSetpoint(setpoint - 1)
    setPostData(
      prevState => ({
      ...prevState,
      setpoint: setpoint - 1,
      })
    )
  }

  const incrementSetpoint = () => {
    updateSetpoint(setpoint + 1)
    setPostData(
      prevState => ({
      ...prevState,
      setpoint: setpoint + 1,
      })
    )
  }

  let modeOptions = [
    {
      label: 'Heat',
      value: 'heat'
    },
    {
      label: 'Cool',
      value: 'cool'
    },
    {
      label: 'Off',
      value: 'off'
    }
  ]

  let durationOptions = []
  const hours = new Array(24).fill(0).map((_, i) => `${i+1}`)

  for (let i = 0; i < hours.length; i++) {
    const hourOption = {
      label: '',
      value: null,
    }

    hourOption.label = hours[i] + `${hours[i] > 1 ? ' hours' : ' hour'}`

    hourOption.value = hours[i] * 60

    durationOptions.push(hourOption)
  }

  const saveRemoteOverrideSettings = () => {
    setProcessing(true)

    getResponse(configuration["backend_host"] + "/ahiapi/"
    + zoneData.thermalZoneEntityKey
    + "/thermalZone/override",
    false,
    "POST",
    `mode=${postData.mode}&` 
      + `setpoint=${postData.setpoint}&`
      + `durationInMinutes=${postData.durationInMinutes}`,
    updateZoneData,
    setProcessing,
    errorMessage,
    setErrorMessage,
    errorMessageRef
    )
  }

  return (<>
    <Modal
      modalTitle={"Remote Override"}
      modalImage={icon_override}
      clearModal={clearModal}
      showMobile={showMobile}
      updateSettings={saveRemoteOverrideSettings}
      renderModalContent={
        (
          <StyledOverrideDiv className="modal-homepage-zone-settings modal-temporary-adjustment">
            <div className="temperature-adjustment">
              <div className="setpoint">
                {setpoint}
                <div
                  className="decrement-setpoint"
                  onClick={decrementSetpoint}
                ><img src={icon_minus} alt='decrease icon' width="20" height="20" /></div>
                <div
                  className="increment-setpoint"
                  onClick={incrementSetpoint}
                ><img src={icon_plus} alt='increase icon' width="20" height="20" /></div>
              </div>
            </div>

            <div className="settings-row">
              <label>
                Mode
              </label>
              <div className="setting-input">
                <select
                  name="mode"
                  onChange={(e) => setPostData(prevState => ({
                    ...prevState,
                    mode: e.target.value,
                    })
                  )}
                >
                  {modeOptions.map((option) => (
                    <option
                      value={option.value}
                      key={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="settings-row">
              <label>
                Duration
              </label>
              <div className="setting-input">
                <select
                  name="duration"
                  onChange={(e) => setPostData(prevState => ({
                    ...prevState,
                    durationInMinutes: parseInt(e.target.value),
                    })
                  )}
                >
                  {durationOptions.map((option) => (
                    <option
                      value={option.value}
                      key={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </StyledOverrideDiv>
        )
      }
    />
  </>)
}

const StyledOverrideDiv = styled.div`
  .settings-row {
    display: flex;
    margin: 0 0 12px;

    > * {
      align-items: center;
      display: flex;
      flex: 1;
    }

    .setting-input {
      justify-content: end;
    }

    label {
      color: #02222B;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
  }

  .temperature-adjustment {
    border-bottom: 2px solid #C2D0D1;
    margin-bottom: 40px;
    padding: 20px;
    text-align:center;

    .setpoint {
      color: #02222B;
      display: inline-block;
      font-size: 72px;
      font-weight: 600;
      letter-spacing: -1.44px;
      line-height: 90px;
      position: relative;

      .decrement-setpoint, 
      .increment-setpoint {
        border: 1px solid var(--gray-300, #D0D5DD);
        border-radius: 8px;
        box-sizing: border-box;
        cursor: pointer;
        font-size: 0;
        left: -64px;
        line-height: 1;
        padding: 12px;
        position: absolute;
        transform: translate(0, -50%);
        top: 50%;
      }

      .increment-setpoint {
        left: auto;
        right: -64px;
        text-align:center;
      }
    }
  }
`

export default ComfortModalRemoteOverride